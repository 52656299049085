import React, { useRef, useState } from "react";
import Modal from "react-modal";
import { IoCloseCircleOutline } from "react-icons/io5";
import style from "./ImageModal.module.css";

Modal.setAppElement("#root");

const ImageModal = ({ isOpen, onRequestClose, imgUrl }) => {
  const [zoom, setZoom] = useState(1);
  const imageRef = useRef(null);

  const handleWheel = (event) => {
    event.preventDefault();
    const newZoom = zoom + event.deltaY * -0.01; // Adjust zoom speed with multiplier
    setZoom(Math.min(Math.max(newZoom, 0.5), 3)); // Clamp zoom between 0.5x and 3x
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={style.modal}
      overlayClassName={style.overlay}
    >
      {/* <button onClick={onRequestClose} className={style.closeButton}>
        <IoCloseCircleOutline size={24} />
      </button> */}
      <div
        className={style.imageContainer}
        onWheel={handleWheel} // Attach wheel event for zooming
      >
        <img
          src={imgUrl}
          alt="Large view"
          className={style.image}
          style={{ transform: `scale(${zoom})`, transformOrigin: 'center center' }} // Apply zoom
          ref={imageRef}
        />
      </div>
    </Modal>
  );
};

export default ImageModal;
