import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import ChatScreen from "../appComponent/Messages/ChatScreen";
import Templates from "../appComponent/Template/Templates";
import { setUserToken, userDetail } from "../Redux/Slices/userSlices";
import { useDispatch, useSelector } from "react-redux";
import Contact from "../appComponent/Contact/Contact";
import FireBaseNotification from "../firebase/FireBaseNotification";
import Timezone from "../appComponent/Timezone/Timezone";
import Setting from "../appComponent/Setting/Setting";

function ServerChat() {
  let { token } = useParams();
  const dispatch = useDispatch();

  if (token) {
    dispatch(userDetail(token));
    dispatch(setUserToken(token));
    return <Navigate to="/" state={{ token: token }} />;
  } else {
    return (window.location.href = process.env.REACT_APP_REDIRECT_URL);
  }
}

function Router() {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  useEffect(()=>{
    if(authToken){
      dispatch(userDetail(authToken));
    }
  },[localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)])
  
  return (
    <BrowserRouter>
      <FireBaseNotification />
      <Routes>
        <Route path="/auth/:token?" element={<ServerChat />} />

        <Route path="/" element={<ChatScreen />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/timezone" element={<Timezone />} />
        <Route path="/inboxes" element={<Setting />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
