export const withoutPTag = (statement) => {
  return statement?.replace(/<\/?p>/g, "");
};

// Function to normalize search input
export const normalizePhoneNumber = (input) => {
  // return input.replace(/[^0-9]/g, ""); // Remove all non-numeric characters
  return input.replace(/[\.\-\(\)\s]/g, "");
};


export const MessageRenderer = ({ msg }) => {
  // Regular expression to match <br>, <br/>, or <br />
  const regex = /(<br\s*\/?>)/i;

  // Split the msg into parts based on <br> tags
  const parts = msg.split(regex);

  // Render each part in a separate paragraph
  const renderMessage = () => {
    return parts.map((part, index) => {
      // Check if the part is an HTML tag
      if (regex.test(part)) {
        // Render the <br> tag itself
        return <br key={index} />;
      } else {
        // Render normal text part
        return <p key={index}>{part}</p>;
      }
    });
  };

  return <div>{renderMessage()}</div>;
};
