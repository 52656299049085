import React, { useCallback, useEffect, useState } from "react";
import Sidemenu from "../SideMenubar/Sidemenu";
import style from "./Contact.module.css";
import CreateContact from "./CreateContact";
import ReservisionApi from "../../helper/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Common/Toster/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlices";
import {
  formatPhoneNumber,
  formatPhoneNumberWithMask,
  removeExtraPlusSigns,
} from "../../Common/commonFunction/CommonFunction";
import TopHeading from "../TopHeading /TopHeading";
import { useTranslation } from "react-i18next";
import { STATUS_CODES } from "../../Utils/StatusCode";
import moment from "moment";
import Loader from "../../Common/loader/Loader";
import Profile from "../Profile/Profile";
import { debounce } from "lodash";
import { normalizePhoneNumber } from "../../Common/ReplacePtag";

const Contact = () => {
  const authToken = useSelector((state) => state.user.userToken);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilen, setSelectedFilen] = useState(null);

  const [imgError, setImgError] = useState("");

  const [showProfile, setShowProfile] = useState(false);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchKey, setSearchKey] = useState("");
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [contactList, setContactList] = useState([]);
  const [userdata, setUserdata] = useState();

  const [totalCount, setTotalCount] = useState();

  const [tableH, setTableH] = useState(window.innerHeight - 120);

  useEffect(() => {
    const handleResize = () => {
      setTableH(window.innerHeight - 130);
    };
    window?.addEventListener("resize", handleResize);
  }, []);

  // show profile screen
  const OpenProfile = (item) => {
    setShowProfile(!showProfile);
    setUserdata(item);
  };

  const handleShow = () => {
    setShow(true);
    setShowProfile(false);
  };

  const handleClose = () => {
    setShowProfile(!showProfile);
  };

  const handleModalClose = () => {
    setShow(false);
  };

  // Contact List api
  const handleGetContact = (isLoadMore = false, isSearch = false) => {
    setIsLoading(true);
    if (!isLoadMore) {
      setOffset(0);
    }
    let newOffset = isLoadMore ? offset + limit : 0;
    const res = ReservisionApi.getContactList(authToken, newOffset, searchKey);
    res.then((data) => {
      setIsLoading(false);
      if (data?.data?.status == "success" || data?.status == "success") {
        // const newData = [...data.contactList];
        // const newContactList = isLoadMore
        //   ? [...contactList, ...data.contactList]
        //   : data.contactList;
        if (isLoadMore === false && isSearch === true) {
          setContactList(data.contactList);
        } else {
          if (isLoadMore === true) {
            setContactList((prevState) => [...prevState, ...data.contactList]);
          } else {
            setContactList(data.contactList);
          }
        }
        setTotalCount(data.totalCount);
        setOffset(newOffset);
        // Toster(data?.message, "success");
      } else if (
        data?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        data?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
      } else {
        // dispatch(userLogoutClear());
        Toster(data?.message, "error");
      }
    });
  };

  useEffect(() => {
    handleGetContact(false, true);
  }, [searchKey]);

  const handleSuccess = () => {
    handleGetContact(false);
  };

  // Manage search template
  // const handleChange = useCallback(
  //   debounce((event) => {
  //     // const normalizedValue = normalizePhoneNumber(event.target.value); // to remove special character
  //     // setSearchKey(normalizedValue);
  //     setSearchKey(event.target.value);
  //     setOffset(0);
  //   }, 500),

  //   []
  // );
  const handleChange = useCallback(
    debounce((event) => {
      let value = event.target.value;
      // Check if the value is likely a phone number (contains digits or phone number formatting characters)
      if (/[\d\-\(\)\.\s]/.test(value) && !/[a-zA-Z]/.test(value)) {
        value = normalizePhoneNumber(value);
      }
      setSearchKey(value);
      setOffset(0);
      setShowProfile(false);
    }, 500),
    []
  );

  // onscroll function for manage pagination of contact list
  const handleScroll = useCallback(
    debounce((e) => {
      const element = e.target;
      if (
        element.scrollHeight - element.scrollTop - element.clientHeight <= 1 &&
        contactList.length < totalCount &&
        !isLoading
      ) {
        handleGetContact(true);
      }
    }, 200),
    [contactList, totalCount, isLoading]
  );
  return (
    <div className="MainScreen">
      <Sidemenu />
      <div className={style.ContactList}>
        <TopHeading
          heading={t("CONTACTS")}
          handleChange={handleChange}
          handleShow={handleShow}
          btnName={t("CREATE_CONTACT")}
        />
        {contactList && contactList.length > 0 ? (
          <>
            <div
              className={style.Contactlist}
              style={{
                height: `${tableH}px`,
              }}
              onScroll={handleScroll}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {/* <th>
                    <div className={style.custom}>
                      <input type="checkbox" />
                    </div>
                  </th> */}
                    <th className={style.NameClass}> {t("NAME")}</th>
                    {/* <th>{t("DATE_ADD")}</th> */}
                  </tr>
                </thead>

                <tbody>
                  {contactList.map((contact) => (
                    <tr key={contact.customerId}>
                      {/* <td>
                      <div className={style.custom}>
                        <input type="checkbox" />
                      </div>
                    </td> */}
                      <td
                        onClick={() => OpenProfile(contact)}
                        style={{ cursor: "pointer" }}
                      >
                        {contact.firstName === "" && contact.lastName === ""
                          ? formatPhoneNumberWithMask(
                              contact.countryCode,
                              contact.contactNo
                            )
                          : contact.firstName !== "" && contact.lastName === ""
                          ? contact.firstName
                          : `${contact.firstName} ${contact.lastName}`}
                        <br />
                        <span style={{ color: "#D0021B", fontSize: "14px" }}>
                          {formatPhoneNumberWithMask(
                            contact.countryCode,
                            contact.contactNo
                          )}
                        </span>
                      </td>

                      {/* <td>
                      {moment(contact.createdDate).startOf("hour").fromNow()}
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p>
              {t("TOTAL_COUNT")} : {contactList.length}/{totalCount || 0}
            </p>
          </>
        ) : (
          <>
            {!isLoading ? (
              <p
                className={style.notFound}
                style={{
                  height: `${tableH}px`,
                }}
              >
                {t("NO_DATA_FOUND")}
              </p>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      {isLoading ? <Loader /> : ""}

      {showProfile && (
        <Profile
          userName={userName}
          setUserName={setUserName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          email={email}
          setEmail={setEmail}
          handleClose={handleClose}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          selectedFilen={selectedFilen}
          setSelectedFilen={setSelectedFilen}
          imgError={imgError}
          setImgError={setImgError}
          userdata={userdata}
          onSuccess={handleSuccess}
          style={{ height: "100%" }}
        />
      )}
      <CreateContact
        show={show}
        handleClose={handleModalClose}
        onSuccess={handleSuccess}
      />
    </div>
  );
};

export default Contact;
