import Modal from "react-bootstrap/Modal";
import styles from "./Template.module.css";
import AlertImg from "../../Assest/Images/AlertImg.png";
import { useTranslation } from "react-i18next";
import Toster from "../../Common/Toster/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlices";
import { useDispatch, useSelector } from "react-redux";
import ReservisionApi from "../../helper/Api";
import { useState } from "react";
import Loader from "../../Common/loader/Loader"

function DeleteTemplate({ showDelete, handleClose, deleteId, onSuccess }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState();

  // DELETE Template API
  const handleDelete = () => {
    setIsLoading(true)
    let res = ReservisionApi.deleteTemplate(authToken, deleteId);
    res.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.status == "success" ||
        response?.status == "success"
      ) {
        Toster(response?.message, "success");
        onSuccess();
        handleClose();
      } else {
        dispatch(userLogoutClear());
        Toster(response?.message, "error");
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal show={showDelete} onHide={handleClose}>
          <Modal.Header className={styles.Heading}>
            <Modal.Title className={styles.AlertHeading}>{t("ALERT")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.AlertBody}>
            <img src={AlertImg} alt="AlertImg"></img>
            <p>{t("DELETE_STATEMENT")}</p>
          </Modal.Body>
          <Modal.Footer className={styles.AlertFooter}>
            <button onClick={handleDelete} className={styles.AlertYes}>
              {t("YES")}
            </button>
            <button onClick={handleClose} className={styles.AlertCancel}>
              {t("CANCEL")}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default DeleteTemplate;
