import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import styles from "./Template.module.css";
import { useForm } from "react-hook-form";
import ReservisionApi from "../../helper/Api";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Toster from "../../Common/Toster/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlices";
import TokenListModel from "./TokenListModel";
import Loader from "../../Common/loader/Loader";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { CommonInput } from "../../Common/commonInput/CommonInput";
import CommonTextarea from "../../Common/commonTextarea/CommonTextarea";
import { ValidationSchema } from "../../Common/ValidationSchema";
import stringLength from "string-length";

function AddTemplate({ show, handleClose, onSuccess }) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);

  const [isLoading, setIsLoading] = useState(false);
  const [showTokenList, setShowTokenList] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [selectedTokenData, setSelectedTokenData] = useState({
    token: "",
    description: "",
  });
  const [cursorPosition, setCursorPosition] = useState(0);
  const [newMessage, setNewMessage] = useState("");
  const [messageLength, setMessageLength] = useState(0);
  const [countMessage, setCountMessage] = useState(0);
  const MAX_CHARACTERS_PER_MESSAGE = 160;

  const handleAddToken = () => {
    setShowTokenList(true);
  };

  const handleTokenClose = () => {
    setShowTokenList(false);
  };

  const handleGetToken = () => {
    setIsLoading(true);
    let res = ReservisionApi.getTokenList(authToken);
    setIsLoading(false);
    res.then((data) => {
      if (data?.data?.status === "success" || data?.status === "success") {
        Toster(data?.message, "success");
        setTokenList(data?.data?.list);
      } else {
        // dispatch(userLogoutClear());
        Toster(data?.message, "error");
      }
    });
  };

  // message length count
  useEffect(() => {
    setMessageLength(stringLength(newMessage));
    const messageCount = Math.ceil(
      stringLength(newMessage) / MAX_CHARACTERS_PER_MESSAGE
    );
    setCountMessage(messageCount);
  }, [newMessage]);

  useEffect(() => {
    if (show) {
      handleGetToken();
    }
  }, [show]);

  const handleCursorChange = (event) => {
    setNewMessage(event.target.value);
    setCursorPosition(event.target.selectionStart);
  };

  useEffect(() => {
    if (selectedTokenData.token) {
      const message = watch("Message") || "";
      const updatedMessage =
        message.slice(0, cursorPosition) +
        selectedTokenData.token +
        message.slice(cursorPosition);
      setValue("Message", updatedMessage);
      setNewMessage(updatedMessage);
      setCursorPosition(cursorPosition + selectedTokenData.token.length);
      // Clear the selected token data to prevent multiple inserts
      setSelectedTokenData({ token: "", description: "" });
    }
  }, [selectedTokenData, setValue, watch, cursorPosition]);

  const onSubmit = (formData) => {
    let Postdata = new FormData();
    Postdata.append("title", formData.Title);
    Postdata.append("text", formData.Message);

    let res = ReservisionApi.addTemplate(authToken, Postdata);
    res.then((response) => {
      if (
        response?.data?.status === "success" ||
        response?.status === "success"
      ) {
        Toster(response?.message, "success");
        handleClose();
        onSuccess();
        reset();
      } else if (
        response?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        response?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
      } else {
        // dispatch(userLogoutClear());
        Toster(response?.message, "error");
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className={styles.ModalHeading}>
            {t("ADD_TEMP")}
          </Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <Loader />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <CommonInput
                type="text"
                placeholder={t("TITLE")}
                name="Title"
                register={register}
                errors={errors}
                validationSchema={ValidationSchema.Title}
                t={t}
              />

              <CommonTextarea
                placeholder={t("MSG")}
                name="Message"
                register={register}
                errors={errors}
                validationSchema={ValidationSchema.Message}
                t={t}
                onClick={handleCursorChange}
                onKeyUp={handleCursorChange}
              />
              <div style={{ textAlign: "right", paddingTop: "5px" }}>
                <div className={styles.characterCount}>
                  <span>{messageLength}</span>
                  <span>
                    {countMessage} {t("MSG_COUNT")}
                  </span>
                </div>
              </div>
              <button
                type="button"
                className="tokenBtn"
                onClick={handleAddToken}
              >
                {t("TOKEN")}
              </button>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={styles.CancelBtn}
                type="button"
                onClick={() => {
                  handleClose();
                  reset();
                }}
              >
                {t("CANCEL")}
              </button>
              <button
                className={`${styles.CancelBtn} ${
                  isValid ? "" : styles.DisabledBtn
                }`}
                type="submit"
                disabled={!isValid}
              >
                {t("DONE")}
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
      <TokenListModel
        showTokenList={showTokenList}
        tokenList={tokenList}
        handleTokenClose={handleTokenClose}
        setSelectedTokenData={setSelectedTokenData}
      />
    </>
  );
}

export default AddTemplate;
