import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import styles from "../Template/Template.module.css";
import { useTranslation } from "react-i18next";
import Toster from "../../Common/Toster/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlices";
import ReservisionApi from "../../helper/Api";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/loader/Loader";
import debounce from "lodash.debounce";
import { CiSearch } from "react-icons/ci";

function TemplateModel({
  showTemplateList,
  handleClose,
  setTemplateMessage,
  contactId,
}) {
  const { t } = useTranslation();
  const authToken = useSelector((state) => state.user.userToken);
  const { customerId } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [totalCount, setTotalCount] = useState();

  // Template List api
  const handleGetTemplate = (isLoadMore = false) => {
    setIsLoading(true);
    if (!isLoadMore) {
      setOffset(0);
    }
    const newOffset = isLoadMore ? offset + 10 : 0;
    const res = ReservisionApi.getTemplateList(authToken, newOffset, searchKey);
    res.then((data) => {
      setIsLoading(false);
      if (data?.data?.status === "success" || data?.status === "success") {
        const newTemplateList = isLoadMore
          ? [...templateList, ...data.templateList]
          : data.templateList;
        setTemplateList(newTemplateList);
        setTotalCount(data.totalCount);
        setOffset(newOffset);
      } else if (
        data?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        data?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
      } else {
        // dispatch(userLogoutClear());
        Toster(data?.message, "error");
      }
    });
  };

  useEffect(() => {
    if (showTemplateList) {
      handleGetTemplate(false);
    }
  }, [showTemplateList, searchKey]);

  // manage for selected token
  const handleTemplateClick = (template) => {
    let Postdata = new FormData();
    Postdata.append("templateId", template?.id);
    Postdata.append("customerId", contactId ? contactId : customerId);
    setIsLoading(true);
    let res = ReservisionApi.fetchTemplateSMS(authToken, Postdata);
    res.then((data) => {
      setIsLoading(false);
      if (data?.data?.status === "success" || data?.status === "success") {
        setTemplateMessage(`${data?.dynamicSms}`); // Set the template message
        handleClose();
      } else if (
        data?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        data?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
      } else {
        // dispatch(userLogoutClear());
        Toster(data?.message, "error");
      }
    });
    setSearchKey("");
  };

  // onscroll function for manage pagination of template list
  const handleScroll = useCallback(
    debounce((e) => {
      const element = e.target;
      if (
        element.scrollHeight - element.scrollTop - element.clientHeight <= 1 &&
        templateList.length < totalCount &&
        !isLoading
      ) {
        handleGetTemplate(true);
      }
    }, 200),
    [templateList, totalCount, isLoading]
  );

  const handleChange = useCallback(
    debounce((event) => {
      setSearchKey(event.target.value);
      setOffset(0);
    }, 500),
    []
  );

  return (
    <Modal
      show={showTemplateList}
      onHide={handleClose}
      className="TemplateModal"
    >
      <Modal.Header>
        <Modal.Title className={styles.ModalHeading}>
          {t("TEMPLATE")}
        </Modal.Title>
      </Modal.Header>
      <Form className="TemplateModal">
        <Form.Group className="SearchTemplateChat">
          <CiSearch size={25} style={{ margin: "5px" }} />
          <Form.Control
            type="text"
            placeholder={t("SEARCH")}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <Modal.Body onScroll={handleScroll}>
          {templateList?.length === 0 ? (
            <p className="NoDataFound" style={{ height: "100%" }}>
              {t("NO_DATA_FOUND")}
            </p>
          ) : (
            templateList.map((item, index) => (
              <div
                key={index}
                className={styles.TemplateData}
                onClick={() => handleTemplateClick(item)} // Pass the entire template object
              >
                {/* <h5>{item.title}</h5> */}
                <strong>{item.title}</strong>
                <p style={{ whiteSpace: "pre-wrap" }}>{item.text}</p>
              </div>
            ))
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className={styles.CancelBtn}
            type="button"
            onClick={handleClose}
          >
            {t("CANCEL")}
          </button>
        </Modal.Footer>
      </Form>
      {isLoading ? <Loader /> : ""}
    </Modal>
  );
}

export default TemplateModel;
