import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

export const CommonInput = ({
  type,
  placeholder,
  name,
  register,
  errors,
  validation,
  validationSchema,
  t,
  ...rest
}) => {
  return (
    <Form.Group className="CreateInput">
      <Form.Control
        type={type}
        placeholder={placeholder}
        name={name}
        {...register(name, {
          ...validationSchema,
        })}
      />
      {errors[name] && (
        <p className="error">
          {t ? t(errors[name].message) : errors[name].message}
        </p>
      )}
    </Form.Group>
  );
};

export const FirstInput = ({
  type,
  placeholder,
  name,
  register,
  errors,
  validation,
  validationSchema,
  t,
  defaultValue = '', 
  ...rest
}) => {
  return (
    <Form.Group className="CreateInput">
      <Form.Control
        type={type}
        placeholder={placeholder}
        name={name}
        defaultValue={defaultValue}
        {...register(name, {
          ...validationSchema,
          validate: (value) =>
            value?.trim() !== "" || "Please enter first name",
          ...validation,
        })}
        {...rest}
      />
      {errors[name] && (
        <p className="error">
          {t ? t(errors[name].message) : errors[name].message}
        </p>
      )}
    </Form.Group>
  );
};

export const LastNameInput = ({
  type,
  placeholder,
  name,
  register,
  errors,
  validation,
  validationSchema,
  t,
  ...rest
}) => {
  return (
    <Form.Group className="CreateInput">
      <Form.Control
        type={type}
        placeholder={placeholder}
        name={name}
        {...register(name, {
          ...validationSchema,
          validate: (value) => value?.trim() !== "" || "Please enter last name",
          ...validation,
        })}
        {...rest}
      />
      {errors[name] && (
        <p className="error">
          {t ? t(errors[name].message) : errors[name].message}
        </p>
      )}
    </Form.Group>
  );
};


CommonInput.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  t: PropTypes.func,
};

FirstInput.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  t: PropTypes.func,
};

LastNameInput.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  t: PropTypes.func,
};


