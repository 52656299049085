import styles from "./MessageLoader.module.css";

import loadingSpinner from "../../Assest/gif/loadingSpinner.gif";

const ChatListLoader = () => (
  <div className={styles.loaderOverlay}>
    <div className={styles.loaderContainer}>
      <img
        src={loadingSpinner}
        alt="Loading Spinner"
        className="customLoader"
      />
    </div>
  </div>
);

export default ChatListLoader;
