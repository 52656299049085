import React, { useEffect, useState } from "react";
import Sidemenu from "../SideMenubar/Sidemenu";
import style from "./Setting.module.css";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Loader from "../../Common/loader/Loader";
import { CiEdit } from "react-icons/ci";
import EditIndex from "./EditIndex";
import Edit from "../../Assest/Images/edit.png";
import ReservisionApi from "../../helper/Api";
import Toster from "../../Common/Toster/Toster";
import { useSelector } from "react-redux";
import { formatPhoneNumberWithMask } from "../../Common/commonFunction/CommonFunction";
;

function Setting() {
  const { t } = useTranslation();

  const authToken = useSelector((state) => state.user.userToken);

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inboxList, setInboxList] = useState([]);
  const [selectedInbox, setSelectedInbox] = useState("");

  const handleIndexList = () => {
    setIsLoading(true);
    ReservisionApi.getAllCompanyInboxList(authToken)
      .then((res) => {
        if (res?.data?.status === "success" || res?.status === "success") {
          Toster(res?.message, "success");
          setInboxList(res.data.company_inbox_list);
        } else {
          Toster(res?.message, "error");
        }
      })
      .catch((error) => {
        Toster("Error fetching inbox list", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleIndexList();
  }, []);

  const handleClose = () => {
    setIsEdit(false);
    setSelectedInbox("");
  };

  const handleAdd = () => {
    setSelectedInbox(""); 
    setIsEdit(true);
  };

  const handleEdit = (inbox) => {
    setSelectedInbox(inbox);
    setIsEdit(true);
  };

  return (
    <div className="TemplatePage">
      <Sidemenu />
      <div className={style.TemplateContent}>
        {/* <h1>Inboxes</h1> */}
        <div className="HeadingName">
          <h1>Inboxes</h1>
          <div className="TopHeading">
            <button onClick={handleAdd}>Add Inbox</button>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : inboxList?.length === 0 ? (
          <p className="NoDataFound">{t("NO_DATA_FOUND")}</p>
        ) : (
          <div
            className="grid-container"
            style={{ background: "none", boxShadow: "none" }}
          >
            {inboxList.map((item, index) => (
              <div key={index} className="inboxCard">
                <div className="dropdown">
                  <h2>{item.name}</h2>
                  <DropdownButton title="&#8230;" id="dropdown-button-drop">
                    <Dropdown.Item onClick={() => handleEdit(item)}>
                      <img src={Edit} alt="Edit" /> <span>{t("EDIT")}</span>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <span>
                  {" "}
                  {item.contact ? formatPhoneNumberWithMask(item.dialCode, item.number) : ""}

                  
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <EditIndex
        isEdit={isEdit}
        handleClose={handleClose}
        selectedInbox={selectedInbox}
        onSuccess={handleIndexList}
      />
    </div>
  );
}

export default Setting;
