import { MASKING } from "../../Utils/Masking";

export const formatPhoneNumber = (value) => {
  const cleaned = ("" + value).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return value;
};

export function removeExtraPlusSigns(str) {
  let newStr = "+" + str;
  return newStr.replace(/\++/g, "+");
}

export function removeAllPlusSigns(str) {
  return str.replace(/\+/g, "");
}


// export const formatPhoneNumberWithMask = (dialCode, phoneNumber) => {
//   // Ensure the phone number is a string
//   const phoneNumberStr = phoneNumber?.toString();

//   // Clean the dial code (remove any non-numeric characters like '+')
//   const cleanedDialCode = dialCode?.toString()?.replace(/\D/g, "");

//   // Clean the phone number (remove non-numeric characters)
//   let cleanedNumber = phoneNumberStr?.replace(/\D/g, "");

//   // Find the corresponding mask for the cleaned dial code
//   const mask = MASKING[cleanedDialCode];

//   // If no mask is found, return the raw cleaned number with the dial code
//   if (!mask) {
//     return `+${cleanedDialCode} ${cleanedNumber}`;
//   }

//   // Apply the mask
//   let maskedNumber = "";
//   let maskIndex = 0;

//   for (let i = 0; i < cleanedNumber?.length && maskIndex < mask.length; i++) {
//     if (mask[maskIndex] === ".") {
//       maskedNumber += cleanedNumber[i];
//       maskIndex++;
//     } else {
//       maskedNumber += mask[maskIndex];
//       maskIndex++;
//       i--;
//     }
//   }

//   // Return the formatted number with dial code prepended
//   return `+${cleanedDialCode} ${maskedNumber}`;
// };

export const formatPhoneNumberWithMask = (dialCode, phoneNumber) => {
  // Ensure the phone number is a string and dial code exists
  if (!phoneNumber || !dialCode) {
    return ""; // Return empty string if phoneNumber or dialCode is missing
  }

  const phoneNumberStr = phoneNumber?.toString();
  const cleanedDialCode = dialCode?.toString()?.replace(/\D/g, "");
  let cleanedNumber = phoneNumberStr?.replace(/\D/g, "");

  const mask = MASKING[cleanedDialCode];
  if (!mask) {
    return `+${cleanedDialCode} ${cleanedNumber}`;
  }

  let maskedNumber = "";
  let maskIndex = 0;

  for (let i = 0; i < cleanedNumber?.length && maskIndex < mask.length; i++) {
    if (mask[maskIndex] === ".") {
      maskedNumber += cleanedNumber[i];
      maskIndex++;
    } else {
      maskedNumber += mask[maskIndex];
      maskIndex++;
      i--;
    }
  }

  return `+${cleanedDialCode} ${maskedNumber}`;
};
