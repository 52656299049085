export const sanitizeHtmlTags = () => ({
  validate: (value) =>
    !/<(\/)?[a-z][\s\S]*>/i.test(value) || "HTML tags are not allowed.",
});

export const ValidationSchema = {
  Email: {
    // required: "ENTER_MAIL",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "ENTER_VALID_MAIL",
    },
  },

  lastName: {
    required: "ENTER_LAST_NAME",
    minLength: {
      value: 1,
      message: "MIN_LAST_NAME",
    },
    maxLength: {
      value: 50,
      message: "MAX_LAST_NAME",
    },
  },

  firstName: {
    required: "ENTER_FIRST_NAME",
    minLength: {
      value: 1,
      message: "MIN_FIRST_NAME",
    },
    maxLength: {
      value: 50,
      message: "MAX_FIRST_NAME",
    },
  },

  Title: {
    required: "ENTER_TITLE",
    minLength: {
      value: 5,
      message: "MIN_ENTER_TITLE",
    },
    maxLength: {
      value: 50,
      message: "MAX_ENTER_TITLE",
    },
  },

  Message: {
    required: "ENTER_MESSAGE",
    minLength: {
      value: 25,
      message: "MIN_ENTER_MESSAGE",
    },
    maxLength: {
      value: 1600,
      message: "MAX_ENTER_MESSAGE",
    },
  },

  Index: {
    required: "ENTER_INDEX",
    minLength: {
      value: 1,
      message: "MIN_ENTER_INDEX",
    },
    maxLength: {
      value: 50,
      message: "MAX_ENTER_INDEX",
    },
  },
};
