import React, { useState } from "react";
import style from "./Sidemenu.module.css";
import Logo from "../../Assest/Images/logo.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Sidemenu({ isList }) {
  const navigate = useNavigate();
  const location = useLocation();
  const appChatCount = useSelector((state) => state.user.appChatCount);

  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isConatct, setIsContact] = useState(false);
  const [isTimeZone, setIsTimeZone] = useState(false);
  const [isSetting, setIsSetting] = useState(false);

  const handleMouseEnter = (setState) => {
    setTimeout(() => setState(true), 100);
  };

  const handleMouseLeave = (setState) => {
    setTimeout(() => setState(false), 100);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("MESSAGE_TOTL")}
    </Tooltip>
  );

  const renderTemplate = (props) => (
    <Tooltip id="button-tooltip-2" {...props}>
      {t("TEMPLATE")}
    </Tooltip>
  );
  const renderContact = (props) => (
    <Tooltip id="button-tooltip-3" {...props}>
      {t("Contact")}
    </Tooltip>
  );

  const renderTimeZone = (props) => (
    <Tooltip id="button-tooltip-4" {...props}>
      {t("Timezone")}
    </Tooltip>
  );

  const renderSetting = (props) => (
    <Tooltip id="button-tooltip-5" {...props}>
      {t("Inbox setting")}
    </Tooltip>
  );

  const handleClick = () => {
    navigate("/templates");
  };

  const handleMessge = () => {
    navigate("/");
  };

  const handleContact = () => {
    navigate("/contact");
  };

  const handleTimeZone = () => {
    navigate("/timezone");
  };

  const handleSetting = () => {
    navigate("/inboxes");
  };

  return (
    <div className={style.Menubar}>
      <img src={Logo} alt="Logo" className={style.Logo} />

      <div className={style.PageLink}>
        <OverlayTrigger
          placement="right"
          delay={{ show: 0, hide: 50 }}
          overlay={renderTooltip}
        >
          <div
            onMouseEnter={() => handleMouseEnter(setIsHovered)}
            onMouseLeave={() => handleMouseLeave(setIsHovered)}
          >
          
                {appChatCount > 0 && isList &&(
                  <div className="badge-light-app-div">
                    <span class="badge badge-light-app" data-length="1">
                      {appChatCount}
                    </span>
                  </div>
                )}
            

            {isHovered ? (
              <p
                onClick={handleMessge}
                className={
                  location.pathname === "/"
                    ? style.ActiveHoverIcon
                    : style.HoverIcon
                }
              ></p>
            ) : (
              <div>
                {location.pathname === "/" ? (
                  <p onClick={handleMessge} className={style.ActiveIcon}></p>
                ) : (
                  <p onClick={handleMessge} className={style.InactiveIcon}></p>
                )}
              </div>
            )}
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="right"
          delay={{ show: 0, hide: 50 }}
          overlay={renderTemplate}
        >
          <div
            onMouseEnter={() => handleMouseEnter(setIsTemplate)}
            onMouseLeave={() => handleMouseLeave(setIsTemplate)}
          >
            {isTemplate ? (
              <p
                onClick={handleClick}
                className={
                  location.pathname === "/templates"
                    ? style.ActiveHovertemplate
                    : style.Hovertemplate
                }
              ></p>
            ) : (
              <div>
                {location.pathname === "/templates" ? (
                  <p onClick={handleClick} className={style.Activetemplate}></p>
                ) : (
                  <p
                    onClick={handleClick}
                    className={style.Inactivetemplate}
                  ></p>
                )}
              </div>
            )}
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="right"
          delay={{ show: 0, hide: 50 }}
          overlay={renderContact}
        >
          <div
            onMouseEnter={() => handleMouseEnter(setIsContact)}
            onMouseLeave={() => handleMouseLeave(setIsContact)}
          >
            {isConatct ? (
              <p
                onClick={handleContact}
                className={
                  location.pathname === "/contact"
                    ? style.ActiveHoverConatct
                    : style.HoverConatct
                }
              ></p>
            ) : (
              <div>
                {location.pathname === "/contact" ? (
                  <p
                    onClick={handleContact}
                    className={style.ActiveContact}
                  ></p>
                ) : (
                  <p
                    onClick={handleContact}
                    className={style.InactiveContact}
                  ></p>
                )}
              </div>
            )}
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="right"
          delay={{ show: 0, hide: 50 }}
          overlay={renderTimeZone}
        >
          <div
            onMouseEnter={() => handleMouseEnter(setIsTimeZone)}
            onMouseLeave={() => handleMouseLeave(setIsTimeZone)}
          >
            {isTimeZone ? (
              <p
                onClick={handleTimeZone}
                className={
                  location.pathname === "/timezone"
                    ? style.ActiveHoverTimeZone
                    : style.HoverTimeZone
                }
              ></p>
            ) : (
              <div>
                {location.pathname === "/timezone" ? (
                  <p
                    onClick={handleTimeZone}
                    className={style.ActiveTimeZone}
                  ></p>
                ) : (
                  <p
                    onClick={handleTimeZone}
                    className={style.InactiveTimeZone}
                  ></p>
                )}
              </div>
            )}
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="right"
          delay={{ show: 0, hide: 50 }}
          overlay={renderSetting}
        >
          <div
            onMouseEnter={() => handleMouseEnter(setIsSetting)}
            onMouseLeave={() => handleMouseLeave(setIsSetting)}
          >
            {isSetting ? (
              <p
                onClick={handleSetting}
                className={
                  location.pathname === "/inboxes"
                    ? style.ActiveHoverSetting
                    : style.HoverSetting
                }
              ></p>
            ) : (
              <div>
                {location.pathname === "/inboxes" ? (
                  <p
                    onClick={handleSetting}
                    className={style.ActiveSetting}
                  ></p>
                ) : (
                  <p
                    onClick={handleSetting}
                    className={style.InactiveSetting}
                  ></p>
                )}
              </div>
            )}
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
}

export default Sidemenu;
