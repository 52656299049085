import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "../Contact/Contact.module.css";
import styles from "../Messages/Chat.module.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import Message from "../../Assest/Images/message.svg";
import { useForm } from "react-hook-form";
import {
  formatPhoneNumber,
  formatPhoneNumberWithMask,
  removeAllPlusSigns,
  removeExtraPlusSigns,
} from "../../Common/commonFunction/CommonFunction";
import ReservisionApi from "../../helper/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Common/Toster/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Loader from "../../Common/loader/Loader";
import { withoutPTag } from "../../Common/ReplacePtag";
import { formateDate } from "../../Common/DateFormate";
import { CgLayoutGrid } from "react-icons/cg";
import { setCustomerId, updateContact } from "../../Redux/Slices/userSlices";

const Profile = ({
  userName,
  setUserName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  handleClose,
  selectedFilen,
  setSelectedFilen,
  imgError,
  setImgError,
  userdata,
  onSuccess,
  showSearchInput,
  handleGetContact,
  hideDropdown = false,
  setActiveChat,
  handleConversationDetail
}) => {
  const {
    formState: { errors, isValid },
  } = useForm({
    // shouldUseNativeValidation: true,
    mode: "onChange",
  });

  const authToken = useSelector((state) => state.user.userToken);

  const { customerId } = useSelector((state) => state.user);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAddImageMessage, setShowAddImageMessage] = useState(false);

  const [nameValid, setNameValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [countryCode, setCountryCode] = useState("");
  const [profileHeight, setProfileHeight] = useState(window.innerHeight - 57);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setProfileHeight(window.innerHeight - 55);
    };

    window?.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMessage = () => {
    navigate("/", {
      state: { data: userdata },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFilen(reader.result);
      };
      reader.readAsDataURL(file);
      setImgError("");

      // Call update API if a new image is selected
      callUpdateContactAPI(file);
    } else {
      setImgError(t("FILE_NOT_ALLOW"));
    }
  };

  const handleImageChange = () => {
    // Click the file input programmatically to open the file selection dialog
    const fileInput = document.getElementById("file-input");
    fileInput.click();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "userName") {
      setUserName(value);
      setNameValid(value.trim() !== "");
    } else if (name === "phoneNumber") {
      const formattedValue = formatPhoneNumber(value);
      setPhoneNumber(value);
      setPhoneValid(/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedValue));
    } else if (name === "email") {
      setEmail(value);
      setEmailValid(value.trim() !== "" && value.endsWith(".com"));
    }
  };

  const handleInputClick = (name) => {
    if (name === "userName") {
      setNameValid(userName.trim() !== "");
      setPhoneValid(true);
      setEmailValid(true);
    } else if (name === "phoneNumber") {
      setPhoneValid(phoneNumber.trim() !== "");
      setNameValid(true);
      setEmailValid(true);
    } else if (name === "email") {
      setEmailValid(email.trim() !== "");
      setNameValid(true);
      setPhoneValid(true);
    }
  };

  const handleInputBlur = (name) => {
    let formData = {};

    if (name === "userName") {
      setNameValid(userName.trim() !== "");

      if (!nameValid) {
        setUserName("");
      } else {
        const names = userName.trim().split(" ");
        if (names.length > 1) {
          const firstName = names[0];
          const lastName = names.slice(1).join(" ");
          formData = { firstName, lastName };
        } else {
          formData = { firstName: names[0], lastName: "" };
        }
      }
    } else if (name === "phoneNumber") {
      setPhoneValid(/^\(\d{3}\) \d{3}-\d{4}$/.test(phoneNumber.trim()));
      if (!phoneValid) {
        setPhoneNumber("");
        setPhoneValid(true);
      } else {
        formData.phoneNumber = phoneNumber;
      }
    } else if (name === "email") {
      setEmailValid(email.trim() !== "" && email.endsWith(".com"));
      if (!emailValid) {
        setEmail("");
        setEmailValid(true);
      } else {
        formData.email = email;
      }
    }
    formData.profileImage = selectedFilen;

    // Call update API if form data is not empty and all fields are valid
    if (
      Object.keys(formData).length > 0 &&
      nameValid &&
      phoneValid &&
      emailValid
    ) {
      callUpdateContactAPI(formData);
    }
  };

  const callUpdateContactAPI = (file) => {
    // Ensure all validations pass and file is provided
    if (!nameValid || !phoneValid || !emailValid || !file) return;

    // Split userName into firstName and lastName
    const names = userName.trim().split(" ");
    const firstName = names[0];
    const lastName = names.slice(1).join(" ") || "";

    // Prepare form data
    const formData = {
      firstName,
      lastName,
      Email: email,
    };

    // Remove non-numeric characters from phoneNumber
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Prepare Postdata for API call
    let Postdata = new FormData();
    Postdata.append("id", userdata?.customerId ? userdata?.customerId : customerId);
    Postdata.append("firstName", formData.firstName);
    Postdata.append("lastName", formData.lastName);
    Postdata.append("email", formData.Email);
    Postdata.append(
      "countryIsoCode",
      removeAllPlusSigns(
        userdata.countryIsoCode ? userdata.countryIsoCode : ""
      )
    );
    Postdata.append("countryCode", userdata.countryCode);
    Postdata.append("contactNo", userdata.contactNo);
    if (file) {
      Postdata.append("profileImage", file);
    } else {
      Postdata.append("profileImage", null); // or use null if your API accepts it
    }

    // Make the API call
    ReservisionApi.updateContactDetail(authToken, Postdata)
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.status === "success" || data?.status === "success") {
          if (showSearchInput) {
            handleGetContact(false, true);
          } else {
            onSuccess();
            handleConversationDetail()
          }

          setActiveChat(prevObject => ({
            ...prevObject,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.Email,
            customerId:data?.data?.customerId
          }));

          dispatch(setCustomerId(data?.data?.customerId));
        } else if (
          data?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
          data?.responseCode === STATUS_CODES.INVALID_TOKEN
        ) {
          window.location.href = process.env.REACT_APP_REDIRECT_URL;
        } else {
          Toster(withoutPTag(data?.message), "error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Toster("An error occurred while updating contact details.", "error");
      });
  };

  // Pre fill data
  useEffect(() => {
    if (userdata) {
      setUserName(
        userdata.firstName !== "undefined" && userdata.lastName !== "undefined"
          ? `${userdata.firstName} ${userdata.lastName}`
          : ""
      );
      // setPhoneNumber(formatPhoneNumber(userdata.contactNo) || "");
      setCountryCode(removeExtraPlusSigns(userdata.countryCode) || "");
      setPhoneNumber(
        formatPhoneNumberWithMask(userdata.countryCode, userdata.contactNo)
      );
      setEmail(userdata.email || "");
      if (userdata.is_show == 0) {
        setSelectedFilen(userdata.profileImage || "");
      } else {
        setSelectedFilen(userdata.ccr_company_customer_image || "");
      }
    }
  }, [userdata, setUserName, setPhoneNumber, setEmail]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={hideDropdown ? styles.userProfile : style.ProfileBox}
          style={hideDropdown ? { height: `${profileHeight}px` } : {}}
        >
          {!hideDropdown && (
            <DropdownButton
              title="&#8230;"
              id={`dropdown-button-drop`}
              className="Btn-drop"
            >
              <Dropdown.Item onClick={handleClose} style={{ fontSize: "15px" }}>
                {t("CLOSE")}
              </Dropdown.Item>
            </DropdownButton>
          )}
          <div
            className={style.userProfile}
            onMouseEnter={() => setShowAddImageMessage(true)}
            onMouseLeave={() => setShowAddImageMessage(false)}
          >
            <div className={style.uploadBox}>
              {selectedFilen ? (
                <div className={style.imgUpload}>
                  <img
                    className={style.afterUploadImg}
                    src={selectedFilen}
                    alt="Uploaded"
                  />
                  {showAddImageMessage && (
                    <h3 className="NotifiyMsg" onClick={handleImageChange}>
                      {t("CHANGE")}
                    </h3>
                  )}
                </div>
              ) : (
                <div className={style.uploadIcon}>
                  <label htmlFor="file-input" className={style.uploadLabel}>
                    <FiUser className="upload-icon" />
                  </label>
                </div>
              )}

              <input
                type="file"
                id="file-input"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <div className="errorMsg">
                {imgError && <p className="error">{imgError}</p>}
              </div>
              {!selectedFilen && showAddImageMessage && (
                <h3 className="NotifiyMsg" onClick={handleImageChange}>
                  {t("ADD_IMG")}
                </h3>
              )}
            </div>
            <form autoComplete="off">
              <div className={style.inputGroup}>
                <input
                  className={`${style.formControl} ${
                    !nameValid && style.errorBorder
                  }`}
                  placeholder="Name"
                  type="text"
                  name="userName"
                  value={userName}
                  onChange={(e) => handleInputChange(e)}
                  onClick={() => handleInputClick("userName")}
                  onBlur={() => handleInputBlur("userName")}
                  autoComplete="off"
                  required
                />
                {!nameValid && (
                  <p className="error">
                    {userName.trim() === ""
                      ? t("ENTER_NAME")
                      : t("ENTER_FULL_NAME")}
                  </p>
                )}
              </div>

              <div className={style.inputGroup}>
                <input
                  className={`${style.formControlPhone} ${
                    !phoneValid && style.errorBorder
                  }`}
                  placeholder="Phone Number"
                  type="text"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleInputChange}
                  onClick={() => handleInputClick("phoneNumber")}
                  onBlur={() => handleInputBlur("phoneNumber")}
                  inputMode="numeric"
                  autoComplete="off"
                  maxLength="14"
                  readOnly
                />
                {!hideDropdown && (
                  <img
                    src={Message}
                    alt=""
                    className={style.Messageicon}
                    onClick={handleMessage}
                  />
                )}
              </div>

              <div className={style.inputGroup}>
                <input
                  className={`${style.formControlEmail} ${
                    !emailValid && style.errorBorder
                  }`}
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => handleInputChange(e)}
                  onClick={() => handleInputClick("email")}
                  onBlur={() => handleInputBlur("email")}
                  autoComplete="off"
                  pattern="[a-z0-9._%+-]+\.com$"
                  required
                />
                {!emailValid && (
                  <p className="error">
                    {email.trim() === ""
                      ? t("ENTER_EMAIL")
                      : t("ENTER_VALID_EMAIL")}
                  </p>
                )}
              </div>

              <div className="form-group">
                <p>{t("DATE")}</p>
                <span>{formateDate(userdata?.createdDate)}</span>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
