import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_ID_KEY,
    uint32.toString(32)
  );
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_TOKEN_KEY,
    uint32.toString(32)
  );
}

class ReservisionApi {
  //token which interact with the API will be stored here.
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    accept: "application/json",
    // "api-key": process.env.REACT_APP_API_KEY,
    "device-token": localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
      ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
      : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN_KEY),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    // Replace multiple consecutive forward slashes with a single forward slash
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );

    const headers = { ...ReservisionApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  //GET TEMPLATE LIST
  static async getTemplateList(authToken, offset, searchKey) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/template?offset=${offset}&search_text=${searchKey}`,
      {},
      "get",
      header
    );
    return res;
  }

  //Add TEMPLATE
  static async addTemplate(authToken, data) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/template`,
      data,
      "post",
      header
    );
    return res;
  }

  //GET TOKEN LIST
  static async getTokenList(authToken) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(`/conversation/tokenList`, {}, "get", header);
    return res;
  }

  //EDIT TEMPLATE
  static async editTemplate(authToken, id, data) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/template/${id}`,
      data,
      "post",
      header
    );
    return res;
  }

  //DELETE TEMPLATE
  static async deleteTemplate(authToken, id) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/template/${id}
      `,
      {},
      "delete",
      header
    );
    return res;
  }

  //GET CONTACT LIST
  static async getContactList(authToken, offset, searchKey) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/contact?offset=${offset}&search_text=${searchKey}`,
      {},
      "get",
      header
    );
    return res;
  }

  //ADD  CONTACT LIST
  static async addContactDetail(authToken, data) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(`/conversation/contact`, data, "post", header);
    return res;
  }

  // UPDTAE CONTACT LIST
  static async updateContactDetail(authToken, data) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(`/conversation/contact`, data, "post", header);
    return res;
  }

  //GET CONVERSATION LIST
  static async getConversationList(data) {
    let header = { "auth-token": ` ${data?.authToken}` };
    let res = await this.request(
      `/conversation?offset=${data?.offset}&search=${data?.search}&inboxId=${data?.inboxId}`,
      {},
      "get",
      header
    );
    return res;
  }

  //GET CONVERSATION DETAILS
  static async getConversationDetail(authToken, data) {
    let header = { "auth-token": ` ${authToken}` };
    // let res = await this.request(`/conversation/detail`, data, "get", header);
    let res = await this.request(
      `/conversation/detail?countryCode=${data.countryCode}&contactNo=${data.contactNo}&limit=${data.limit}&offset=${data.offset}&inboxId=${data.inboxId}`,
      {},
      "get",
      header
    );

    return res;
  }

  // send Message
  static async fetchSendMessage(authToken, data) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/sendMessage`,
      data,
      "post",
      header
    );
    return res;
  }

  // Template dynamic sms
  static async fetchTemplateSMS(authToken, data) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/templateDynamicSms`,
      data,
      "post",
      header
    );
    return res;
  }

  // get user detail
  static async getUserDetail(authToken) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(`/user/detail`, {}, "get", header);
    return res;
  }

  // get timezone list
  static async GetTimezoneList(authToken, limit, offset, searchKey) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/timezone?limit=${limit}&offset=${offset}&searchKey=${searchKey}`,
      {},
      "get",
      header
    );
    return res;
  }

  // update timezone list
  static async updateTimezone(authToken, data) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(`/timezone`, data, "post", header);
    return res;
  }

  //GET company inbox list api
  static async getCompanyInboxList(authToken) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/companyInboxes`,
      {},
      "get",
      header
    );
    return res;
  }

    //GET company inbox list api
    static async getAllCompanyInboxList(authToken) {
      let header = { "auth-token": ` ${authToken}` };
      let res = await this.request(
        `/conversation/companyInboxes?fetchAll=1`,
        {},
        "get",
        header
      );
      return res;
    }

    //Add company inbox name
    static async addInbox(authToken, data) {
      let header = { "auth-token": ` ${authToken}` };
      let res = await this.request(
        `/conversation/addCompanyInbox`,
        data,
        "post",
        header
      );
      return res;
    }

  //Update company inbox name
  static async updateIndex(authToken, data) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/companyInbox`,
      data,
      "post",
      header
    );
    return res;
  }

  //Update company inbox name
  static async selectedIndex(authToken, data) {
    let header = { "auth-token": ` ${authToken}` };
    let res = await this.request(
      `/conversation/setInboxSelected`,
      data,
      "post",
      header
    );
    return res;
  }

    //Get list of active numbers api
    static async getActiveNumList(authToken) {
      let header = { "auth-token": ` ${authToken}` };
      let res = await this.request(
        `/conversation/activeNumbers`,
        {},
        "get",
        header
      );
      return res;
    }
}
export default ReservisionApi;
