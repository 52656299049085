import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ReservisionApi from "../../helper/Api";
import Toster from "../../Common/Toster/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";

const initialState = {
  userToken: null,
  contactNo: null,
  countryCode: null,
  newMsgNotification: null,
  newMsgCount: null,
  historyObjUpdate: null,
  currentUser: {},
  companyInfo: {},
  currentTimeZoneState: {},
  appChatCount: 0,
  userContactList: "",
  customerId: ""
};

// Thunk for user login
export const userDetail = createAsyncThunk(
  "user/userDetail",
  async (authToken, { rejectWithValue, getState }) => {
    try {
      const response = await ReservisionApi.getUserDetail(authToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user detail data
export const userChatDetail = createAsyncThunk(
  "user/userChatDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReservisionApi.getConversationList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for update contact
// export const updateContact = createAsyncThunk(
//   "user/updateContact",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await ReservisionApi.updateContactDetail(
//         data.authToken,
//         data.Postdata
//       );
//       return response;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state, action) => {
      state.userToken = null;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setNewMsgNotification: (state, action) => {
      if (action.payload) {
        state.newMsgNotification = action.payload;
      }
    },
    updateContactListName: (state, action) => {
      if (action.payload) {
        state.userContactList = action.payload;
      }
    },
    setHistoryNotification: (state, action) => {
      if (action.payload) {
        state.historyObjUpdate = action.payload;
      }
    },
    setUserContact: (state, action) => {
      state.contactNo = action?.payload?.contactNo;
      state.countryCode = action?.payload?.countryCode;
    },
    setCurrentTimeZone: (state, action) => {
      state.currentTimeZoneState = action.payload;
    },
    setUpdateChatCount: (state, action) => {
      state.appChatCount = action.payload;
    },
    setCustomerId: (state, action) => {
      state.customerId = action?.payload;
    },
    setNewMsgNotificationCount: (state, action) => {
      if (action.payload) {
        state.newMsgCount = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    // user detail api
    builder.addCase(userDetail.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userDetail.fulfilled, (state, action) => {
      const response = action.payload;
      if (response?.status === "success") {
        state.currentUser = response?.userDetail;
        state.currentTimeZoneState = response?.timeZone;
        state.companyInfo = response?.companyInfo;
        state.isLoading = false;
        state.success = true;
      } else if (
        response?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        response?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
      }
    });

    builder.addCase(userDetail.rejected, (state, action) => {
      state.isLoading = false;
    });

    // converstaion detail api
    builder.addCase(userChatDetail.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userChatDetail.fulfilled, (state, action) => {
      const response = action.payload;
      if (response?.status === "success") {
        state.isLoading = false;
        state.success = true;
        // state.userContactList = response.contactList;
      } else if (
        response?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        response?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
      }
    });

    builder.addCase(userChatDetail.rejected, (state, action) => {
      state.isLoading = false;
    });

    //Update contact api
    // builder.addCase(updateContact.pending, (state) => {
    //   state.isLoading = true;
    // });

    // builder.addCase(updateContact.fulfilled, (state, action) => {
    //   const response = action.payload;
    //   if (response?.status === "success") {
    //     state.isLoading = false;
    //     state.success = true;
    //     state.customerId = response?.data?.customerId;
    //   } else if (
    //     response?.responseCode === STATUS_CODES.INVALID_TOKEN ||
    //     response?.responseCode === STATUS_CODES.INVALID_TOKEN
    //   ) {
    //     window.location.href = process.env.REACT_APP_REDIRECT_URL;
    //   }
    // });

    // builder.addCase(updateContact.rejected, (state, action) => {
    //   state.isLoading = false;
    // });
  },
});

export const {
  userLogoutClear,
  setUserToken,
  setUserContact,
  setUpdateChatCount,
  setNewMsgNotification,
  updateContactListName,
  setHistoryNotification,
  setCurrentTimeZone,
  setCustomerId,
  setNewMsgNotificationCount
} = userSlice.actions;
export default userSlice.reducer;
