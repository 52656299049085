//firebase
import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId:  process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId:  process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

function generateFirebaseToken() {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => {
      // console.log("firebase device token", currentToken);
      if (currentToken) {
        localStorage.setItem(
          process.env.REACT_APP_FIREBASE_TOKEN,
          currentToken
        );
      } else {
        console.log("Failed to generate the registration token.");
      }
    })

    .catch((err) => {
      console.log(
        "An error occurred when requesting to receive the token.",
        err
      );
    });
}

export function requestNotificationPermission() {
  // Check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support notifications.");
    return;
  }

  // Check if permission has already been granted
  if (Notification.permission === "granted") {
    // console.log("Permission to receive notifications has been granted");
    generateFirebaseToken();
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Permission to receive notifications has been granted");
        generateFirebaseToken();
      }
    });
  }
}

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
