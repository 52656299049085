import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "./ChatList.module.css";
import { Form } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import Chat from "./Chat";
import { useTranslation } from "react-i18next";
import {
  setUserContact,
  setUpdateChatCount,
  updateContactListName,
  userChatDetail,
} from "../../Redux/Slices/userSlices";
import Toster from "../../Common/Toster/Toster";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import Loader from "../../Common/loader/Loader";
import { formatUpdatedDate } from "../../Common/DateFormate";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useLocation } from "react-router-dom";
import { normalizePhoneNumber } from "../../Common/ReplacePtag";
import chatWithEmoji from "../../Assest/Images/chat_with_emoji.png";
import Dropdown from "react-bootstrap/Dropdown";
import ReservisionApi from "../../helper/Api";
import { RxCross2 } from "react-icons/rx";
import PhoneInput from "react-phone-input-2";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { formatPhoneNumberWithMask } from "../../Common/commonFunction/CommonFunction";
import { AiOutlineClose } from "react-icons/ai";
import ChatListLoader from "../../Common/loader/ChatListLoader";

const ChatList = ({ setIsList }) => {
  const authToken = useSelector((state) => state.user.userToken);
  const searchInputRef = useRef(null);
  const currentTimeZoneState = useSelector(
    (state) => state.user.currentTimeZoneState
  );

  const historyObjUpdate = useSelector((state) => state.user.historyObjUpdate);
  const appChatCount = useSelector((state) => state.user.appChatCount);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const userDetail = location?.state?.data;

  const userContactForNavigate = location?.state?.contactNo;
  const userCountryCodeForNavigate = location?.state?.countryCode;

  const [activeChat, setActiveChat] = useState(userDetail ? userDetail : null);

  const [isChatListActive, setIsChatListActive] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 600);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [contactList, setContactList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const [contactsearchList, setContactsearchList] = useState([]);

  const [showSearchInput, setShowSearchInput] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");

  const [selectedIndexId, setSelectedIndexId] = useState("");
  const [inboxLoaded, setInboxLoaded] = useState(false);

  const [inboxList, setInboxList] = useState([]);

  const [phone, setPhone] = useState("");
  const [dialCode, setDialCode] = useState(1);
  const [countryCode, setCountryCode] = useState("us");
  const [isValidNumber, setIsValidNumber] = useState();
  const [searchValue, setSearchValue] = useState("");

  const [isLoadingContacts, setIsLoadingContacts] = useState(true);
  const [loadMoreContact, setLoadMoreContact] = useState(false);

  useEffect(() => {
    const combineNumber = `+${dialCode}${phone}`;

    // const isPossible = isValidPhoneNumber(combineNumber);
    const isValid = isValidPhoneNumber(combineNumber);
    const isPossible = isPossiblePhoneNumber(combineNumber);
    if (isValid && isPossible) {
      setIsValidNumber(true);
    } else {
      setIsValidNumber(false);
    }
  }, [dialCode, phone, showSearchInput]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
      setIsLargeScreen(window.innerWidth > 600);
    };

    window?.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to split the title into firstName and lastName
  const splitTitle = (title) => {
    const parts = title.split(" ");
    return {
      firstName: parts[0] || "",
      lastName: parts.slice(1).join(" ") || "",
    };
  };

  // Function to remove unreadcount
  function removeUnreadCount(contact) {
    let updatedContact = contactList.map((contactInfo) => {
      if (
        contactInfo.contactNo === contact.contactNo &&
        contactInfo.inboxId === contact.inboxId &&
        contactInfo.unreadCount > 0
      ) {
        return { ...contactInfo, unreadCount: 0 };
      }
      return contactInfo;
    });

    if (contact.unreadCount > 0 && appChatCount > 0) {
      dispatch(setUpdateChatCount(appChatCount - 1));
    }
    setContactList(updatedContact);

    // Now update the inboxList's unreadInboxCount for the specific inboxId
    let updatedInboxList = inboxList.map((inbox) => {
      if (inbox.id === contact.inboxId && contact.unreadCount > 0) {
        return {
          ...inbox,
          unreadInboxCount: Math.max(0, inbox.unreadInboxCount - 1),
        };
      }
      return inbox;
    });
    setInboxList(updatedInboxList);
  }

  // Function to append new notification message in chatlist
  function reorderArrayByNewMessage(array, historyObjUpdate) {
    if (isLoadingContacts) {
      return;
    }

    let matchingElements = [];
    let nonMatchingElements = [];

    let contactExists = false;

    const isActiveChatMatched =
      activeChat && activeChat.contactNo === historyObjUpdate.contactNo;
    if (
      selectedIndexId === "" ||
      selectedIndexId === historyObjUpdate.inboxId
    ) {
      array.forEach((element) => {
        if (
          historyObjUpdate.contactNo === element.contactNo &&
          historyObjUpdate.inboxId === element.inboxId
        ) {
          contactExists = true;
          matchingElements.push({
            ...element,
            unreadCount: isActiveChatMatched ? 0 : historyObjUpdate.unreadCount,
            updatedDate: historyObjUpdate.updatedDate,
            lastMsg: historyObjUpdate.lastMsg,
          });
        } else {
          nonMatchingElements.push(element);
        }
      });

      if (contactExists) {
        setContactList([...matchingElements, ...nonMatchingElements]);
      } else {
        let { firstName, lastName } = splitTitle(historyObjUpdate.title || "");
        let newContact = {
          inboxId: historyObjUpdate.inboxId,
          name: historyObjUpdate.inboxName,
          ccr_company_customer_image: "",
          is_show: "0",
          profileImage: "",
          customerId: "",
          firstName,
          lastName,
          email: "",
          companyId: "",
          msgSenderType: historyObjUpdate.senderType,
          lastMsg: historyObjUpdate.lastMsg,
          isMedia: historyObjUpdate.isMedia,
          unreadCount: historyObjUpdate.unreadCount,
          countryCode: historyObjUpdate.countryCode,
          countryIsoCode: "",
          contactNo: historyObjUpdate.contactNo,
          updatedDate: historyObjUpdate.crdOrg,
          status: historyObjUpdate.status,
          upd: historyObjUpdate.crdOrg,
        };

        // Add the new contact at the 0th index
        setContactList([newContact, ...array]);
      }
    }
  }

  // show count in inbox dropdown
  function reorderInboxListByNewMessage(inboxList, historyObjUpdate) {
    // Separate matching and non-matching elements
    let matchingElements = [];
    let nonMatchingElements = [];

    const isActiveChatMatched =
      activeChat && activeChat.contactNo === historyObjUpdate.contactNo;
    dispatch(
      setUpdateChatCount(
        isActiveChatMatched
          ? historyObjUpdate.badge > 0
            ? historyObjUpdate.badge - 1
            : 0
          : historyObjUpdate.badge
      )
    );
    inboxList.forEach((inbox) => {
      if (historyObjUpdate.inboxId === inbox.id) {
        matchingElements.push({
          ...inbox,
          unreadInboxCount: isActiveChatMatched
            ? historyObjUpdate.unreadInboxCount > 0
              ? historyObjUpdate.unreadInboxCount - 1
              : 0
            : historyObjUpdate.unreadInboxCount,
          id: historyObjUpdate.inboxId,
        });
      } else {
        nonMatchingElements.push(inbox);
      }
    });
    setInboxList([...matchingElements, ...nonMatchingElements]);
  }

  // conversation List api
  const handleChatList = async (isLoadMore = false, showLoader = true) => {
    if (!isLoadMore) {
      setOffset(0);
    }

    if (isLoadMore === true) {
      if (showLoader == true) {
        setLoadMoreContact(true);
      }
    }
    const newOffset = isLoadMore ? offset + limit : 0;
    const data = {
      authToken: authToken,
      offset: newOffset,
      search: searchKey,
      inboxId: selectedIndexId,
    };
    setIsLoadingContacts(true);
    const response = await dispatch(userChatDetail(data));
    setLoadMoreContact(false);
    const responseJson = response.payload;
    setIsLoading(false);
    if (responseJson?.status === "success") {
      const newContactList = isLoadMore
        ? [...contactList, ...responseJson?.contactList]
        : responseJson?.contactList;
      setContactList(newContactList);

      setIsList(newContactList?.length > 0 ? true : false);
      dispatch(updateContactListName(newContactList));

      setOffset(newOffset);
      setTotalCount(responseJson?.totalCount);
      dispatch(setUpdateChatCount(responseJson?.unreadChatCount));

      if (!activeChat && responseJson.contactList.length > 0) {
        // setActiveChat(data.contactList[0]);
      }
    } else if (
      responseJson?.responseCode === STATUS_CODES.INVALID_TOKEN ||
      data?.responseCode === STATUS_CODES.INVALID_TOKEN
    ) {
      window.location.href = process.env.REACT_APP_REDIRECT_URL;
    } else {
      Toster(data?.message, "error");
    }
    setIsLoadingContacts(false);
    // setLoadMoreContact(false);
  };

  useEffect(() => {
    if (historyObjUpdate) {
      reorderInboxListByNewMessage(inboxList, historyObjUpdate);
      reorderArrayByNewMessage(contactList, historyObjUpdate);
    }
  }, [historyObjUpdate]);

  useEffect(() => {
    if (inboxLoaded && (selectedIndexId || !showSearchInput)) {
      handleChatList(false);
    }
  }, [inboxLoaded, selectedIndexId, searchKey, showSearchInput]);

  const handleChange = useCallback(
    debounce((event) => {
      let value = event.target.value;

      if (/[\d\-\(\)\.\s]/.test(value) && !/[a-zA-Z]/.test(value)) {
        value = normalizePhoneNumber(value);
      }
      setSearchKey(value);
      setOffset(0);
    }, 200),
    []
  );

  const handleUserInfoClick = (contact) => {
    setActiveChat(contact);
    setIsChatListActive(false);
  };

  const handleBackButtonClick = () => {
    setIsChatListActive(true);
    setActiveChat(null);
  };

  const handleScroll = useCallback(
    debounce((e) => {
      const element = e.target;
      if (
        element.scrollHeight - element.scrollTop - element.clientHeight <= 1 &&
        contactList.length < totalCount &&
        !isLoading
      ) {
        handleChatList(true);
      }
    }, 200),
    [contactList, totalCount, isLoading]
  );

  // Dispatch setUserContact action when activeChat changes
  useEffect(() => {
    if (activeChat) {
      dispatch(
        setUserContact({
          contactNo: activeChat.contactNo,
          countryCode: activeChat.countryCode,
        })
      );
    }
  }, [activeChat, dispatch]);

  // Assuming you have a function to handle the toast click
  const handleToastClick = (clickedUser) => {
    const contactToActivate = contactList.find(
      (contact) =>
        contact.contactNo === clickedUser.contactNo &&
        contact.countryCode === clickedUser.countryCode
    );
    if (contactToActivate) {
      setActiveChat(contactToActivate);
      if (isSmallScreen) {
        setIsChatListActive(false);
      }
    }
  };

  // useEffect(() => {
  //   if (userContactForNavigate && userCountryCodeForNavigate) {
  //     handleToastClick({
  //       contactNo: userContactForNavigate,
  //       countryCode: userCountryCodeForNavigate,
  //     });
  //   }
  // }, [userContactForNavigate, userCountryCodeForNavigate, contactList]);

  const handleAddChatClick = () => {
    setShowSearchInput(true);
    setSearchKey("");
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    setContactsearchList([]);
  };

  const handleSearchChange = (e) => {
    let value = e.target.value;
    setSearchValue(value); // Keep the formatted value in the input

    // Normalize the phone number (e.g., remove special characters) if it matches the phone pattern
    if (/[\d\-\(\)\.\s]/.test(value) && !/[a-zA-Z]/.test(value)) {
      const cleanedValue = normalizePhoneNumber(value);
      setSearchKey(cleanedValue); // Set the cleaned value for API use
      setPhone(cleanedValue);
    } else {
      setSearchKey(value);
    }

    setOffset(0);

    if (value.trim() === "") {
      setContactsearchList([]);
    }
  };

  // contact list api
  const handleGetContact = async (isLoadMore = false, isSearch = false) => {
    setIsLoading(true);
    if (!isLoadMore) {
      setOffset(0);
    }
    const newOffset = isLoadMore ? offset + limit : 0;
    const res = await ReservisionApi.getContactList(
      authToken,
      newOffset,
      searchKey
    );
    setIsLoading(false);
    if (res?.data?.status === "success" || res?.status === "success") {
      if (isSearch) {
        setContactsearchList(res?.contactList);
        dispatch(updateContactListName(res?.contactList));
      } else if (isLoadMore) {
        dispatch(updateContactListName(...contactsearchList, res?.contactList));
        setContactsearchList((prevState) => [
          ...prevState,
          ...res?.contactList,
        ]);
      }
      setTotalCount(res?.totalCount);
      setOffset(newOffset);
    }
  };

  useEffect(() => {
    if (showSearchInput && searchKey.trim() !== "") {
      handleGetContact(false, true);
    } else {
      setContactsearchList([]);
    }
  }, [searchKey, showSearchInput]);

  const handelPhoneChange = (phone, data) => {
    setDialCode(data.dialCode);
    setCountryCode(data.countryCode);
  };

  const handleIndexList = () => {
    ReservisionApi.getCompanyInboxList(authToken)
      .then((res) => {
        if (res?.data?.status === "success" || res?.status === "success") {
          Toster(res?.message, "success");

          const updatedInboxList = res.data.company_inbox_list.map((inbox) => ({
            ...inbox, // Keep all other inbox properties
            unreadInboxCount: inbox.unreadMessages,
          }));

          setInboxList(updatedInboxList);

          const selectedInbox = updatedInboxList.find(
            (inbox) => inbox.selected
          );

          setSelectedIndexId(selectedInbox?.id || "");

          if (selectedInbox) {
            setSelectedOption({
              name: selectedInbox.name,
              contact: selectedInbox.contact,
              inboxId: selectedInbox.id,
              dialCode: selectedInbox.dialCode,
              number: selectedInbox.number,
            });
          } else {
            setSelectedOption({
              name: "All Inboxes",
              contact: "",
              dialCode: "",
              number: "",
            });
          }
          setInboxLoaded(true);
        } else {
          Toster(res?.message, "error");
        }
      })
      .catch((error) => {
        Toster("Error fetching inbox list", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleIndexList();
  }, []);

  const handleSelect = (eventKey) => {
    const [inboxId, name, contact, dialCode, number] = eventKey.split(" | ");
    setSelectedOption({ name, contact, dialCode, number });
    setSelectedIndexId(name === "All Inboxes" ? "" : inboxId);
    // Call the selectedIndex API with the selected inboxId
    let Postdata = new FormData();
    Postdata.append("inboxId", name === "All Inboxes" ? "" : inboxId);

    ReservisionApi.selectedIndex(authToken, Postdata)
      .then((response) => {
        if (
          response?.data?.status === "success" ||
          response?.status === "success"
        ) {
          setActiveChat(null);
          setSearchValue("");
          setSearchKey("");
          if (searchInputRef.current) {
            searchInputRef.current.value = "";
          }
          // Toster(response?.message, "success");
        } else if (
          response?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
          response?.responseCode === STATUS_CODES.INVALID_TOKEN
        ) {
          window.location.href = process.env.REACT_APP_REDIRECT_URL;
        } else {
          Toster(response?.message, "error");
        }
      })
      .catch((error) => {
        Toster("Error selecting inbox", "error");
      });
  };

  return (
    <div className="chatContent">
      {isLoading ? <Loader /> : ""}
      {isChatListActive ? (
        <div className={style.chatList}>
          <div className="DropdownOptions">
            {!showSearchInput ? (
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle id="dropdown-item-button">
                  <div className="textDisplay">
                    <h2 data-fulltext={selectedOption.name}>
                      {selectedOption.name}
                    </h2>

                    {selectedOption.contact && (
                      <span>
                        {" "}
                        {formatPhoneNumberWithMask(
                          selectedOption?.dialCode,
                          selectedOption?.number
                        )}
                      </span>
                    )}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="All | All Inboxes">
                    {t("ALL_INBOX")}
                  </Dropdown.Item>
                  {inboxList?.map((inbox) => (
                    <Dropdown.Item
                      key={inbox.id}
                      eventKey={`${inbox.id} | ${inbox.name} | ${inbox.contact} | ${inbox.dialCode} | ${inbox.number}`}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {inbox.name} <br />
                          <span>
                            {formatPhoneNumberWithMask(
                              inbox.dialCode,
                              inbox.number
                            )}
                          </span>
                        </div>

                        {inbox.unreadInboxCount > 0 && (
                          <strong className="badge badge-light" data-length="1">
                            {inbox.unreadInboxCount}
                          </strong>
                        )}
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <h1>{t("NEW_CHAT")}</h1>
            )}
            {!showSearchInput ? (
              <p type="button" onClick={handleAddChatClick} />
            ) : (
              <RxCross2
                className={style.crossIcon}
                onClick={() => {
                  setShowSearchInput(false);
                  setSearchValue("");
                  setSearchKey("");
                  setDialCode(1);
                  if (searchInputRef.current) {
                    searchInputRef.current.value = ""; // Clear the input field directly
                  }
                }}
              />
            )}
          </div>
          {!showSearchInput && (
            <div className={style.SearchBox}>
              <Form.Group className="SearchInput">
                <CiSearch size={25} style={{ margin: "5px" }} />
                <Form.Control
                  type="text"
                  placeholder={
                    showSearchInput
                      ? "Enter name or phone number"
                      : t("SEARCH_PLACEHOLDER")
                  }
                  ref={searchInputRef}
                  onChange={showSearchInput ? handleSearchChange : handleChange}
                />
                <AiOutlineClose
                  size={20}
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSearchKey("");
                    if (searchInputRef.current) {
                      searchInputRef.current.value = ""; // Clear the input field directly
                    }
                  }}
                />
              </Form.Group>
            </div>
          )}
          {showSearchInput && (
            <div className={style.SearchBoxNew}>
              <div className="reactTelInput">
                <PhoneInput
                  country={"us"}
                  onChange={(phone, country) => {
                    handelPhoneChange(phone, country);
                  }}
                  disableCountryCode={false} // Allow country code to show in the input
                  inputProps={{
                    readOnly: true,
                    style: {
                      display: "block",
                      width: "0px ",
                      height: "40px",
                      borderRadius: "unset",
                      borderRight: "unset",
                      paddingLeft: "28px",
                      border: "none",
                      background: "none",
                    },
                  }}
                />
              </div>
              <Form.Group className="SearchInputTel">
                <Form.Control
                  type="text"
                  placeholder={"Enter name or phone number"}
                  ref={searchInputRef}
                  onChange={handleSearchChange}
                  style={{
                    borderRadius: "unset",
                    borderRight: "unset",
                  }}
                  value={searchValue}
                />
                <AiOutlineClose
                  size={20}
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSearchValue("");
                    setSearchKey("");
                    if (searchInputRef.current) {
                      searchInputRef.current.value = "";
                    }
                  }}
                />
              </Form.Group>
            </div>
          )}

          {!showSearchInput && (
            <div className={style.ChatBox} onScroll={handleScroll}>
              {contactList?.length === 0 ? (
                <p className="NoResultFound">{t("NO_RESULT_FOUND")}</p>
              ) : (
                contactList.map((contact) => (
                  <div
                    className={`${style.userInfo} ${
                      activeChat?.customerId === contact.customerId &&
                      activeChat?.inboxId === contact.inboxId
                        ? style.active
                        : ""
                    }`}
                    key={`${contact.customerId}-${contact.inboxId}`} // Ensure the key is unique
                    onClick={() => {
                      removeUnreadCount(contact);
                      isSmallScreen && !isLargeScreen
                        ? handleUserInfoClick(contact)
                        : setActiveChat(contact);
                    }}
                  >
                    {selectedOption.name === "All Inboxes" && (
                      <p>{contact.name}</p>
                    )}

                    <div className={style.userInfoBox}>
                      {/* <h3>
                        {contact.firstName && contact.lastName
                          ? `${contact.firstName} ${contact.lastName}`
                          : formatPhoneNumberWithMask(
                              contact.countryCode,
                              contact.contactNo
                            )}
                      </h3> */}
                     <h3>
  {contact.firstName === "" && contact.lastName === ""
    ? formatPhoneNumberWithMask(contact.countryCode, contact.contactNo)
    : contact.firstName !== "" && contact.lastName === ""
    ? contact.firstName
    : `${contact.firstName} ${contact.lastName}`}
</h3>


                      <h5>
                        {formatUpdatedDate(
                          contact.updatedDate,
                          currentTimeZoneState?.timeZoneTxt
                        )}
                      </h5>
                    </div>
                    <div className={style.userInfoBox}>
                      <p className="contact-text">
                        {contact.status === "Failed" && (
                          <span
                            style={{ color: "#e45839", marginRight: "5px" }}
                          >
                            {contact.status}
                          </span>
                        )}
                        {contact.lastMsg === ""
                          ? t("IMAGE")
                          : contact.lastMsg?.replace(/<\/?br\s?\/?>/g, " ")}
                      </p>
                      {contact?.unreadCount > 0 && (
                        <span className="badge badge-light" data-length="1">
                          {contact?.unreadCount}
                        </span>
                      )}
                    </div>
                  </div>
                ))
              )}
              {loadMoreContact && <ChatListLoader />}
            </div>
          )}

          {showSearchInput && searchKey.trim() !== "" && (
            <div className={style.contactChatBox}>
              {contactsearchList?.map((contact) => (
                <div
                  key={contact.customerId}
                  className={style.userInfo}
                  onClick={() => {
                    setActiveChat(contact);
                    setShowSearchInput(false);
                    setSearchValue("");
                    setSearchKey("");
                    if (searchInputRef.current) {
                      searchInputRef.current.value = "";
                    }
                  }}
                >
                  <h3>
                    {contact?.firstName == "" && contact?.lastName == ""
                      ? t("UNKNOWN_USER")
                      : `${contact?.firstName} ${contact?.lastName}`}
                  </h3>
                  <p>
                    {formatPhoneNumberWithMask(
                      contact.countryCode,
                      contact.contactNo
                    )}
                  </p>
                </div>
              ))}
            </div>
          )}
          {showSearchInput &&
            searchKey.trim() !== "" &&
            isValidNumber &&
            contactsearchList.length == 0 && (
              <div className={style.contactChatBox}>
                <div
                  className={style.userInfo}
                  onClick={() => {
                    setActiveChat({
                      firstName: "Unknown",
                      lastName: "User",
                      contactNo: phone,
                      countryCode: `+${dialCode}`,
                      countryIsoCode: countryCode,
                      isNewUser: true,
                    });
                    setShowSearchInput(false);
                    setSearchValue("");
                    setSearchKey("");
                    if (searchInputRef.current) {
                      searchInputRef.current.value = "";
                    }
                  }}
                >
                  <h3>{t("UNKNOWN_USER")}</h3>
                  <p>{formatPhoneNumberWithMask(dialCode, phone)}</p>
                </div>
              </div>
            )}
        </div>
      ) : (
        isSmallScreen &&
        activeChat !== null && (
          <Chat
            contact={activeChat}
            onBackClick={handleBackButtonClick}
            onSuccess={handleChatList} // Use handleChatList as onSuccess handler
            selectedIndexId={selectedIndexId}
            handleGetContact={handleGetContact}
            showSearchInput={showSearchInput}
            setActiveChat={setActiveChat}
          />
        )
      )}

      {isLargeScreen && activeChat !== null && (
        <Chat
          contact={activeChat}
          onSuccess={handleChatList}
          selectedIndexId={selectedIndexId}
          handleGetContact={handleGetContact}
          showSearchInput={showSearchInput}
          setActiveChat={setActiveChat}
        />
      )}

      {isLargeScreen && activeChat == null && (
        <div className={style.chatContainer}>
          <p className={style.NoContact}>
            {" "}
            <img
              className={style.noChatSelected}
              alt="ChatEmoji"
              src={chatWithEmoji}
            />
            {t("NO_CONTACT_MSG")}
          </p>
        </div>
      )}
    </div>
  );
};

export default ChatList;
