import React, { useState } from "react";
import Sidemenu from "../SideMenubar/Sidemenu";
import "../../Assest/Styles/Common.css";
import ChatList from "./ChatList";
import { useLocation } from "react-router-dom";

function ChatScreen() {
  const location = useLocation();

  const userToken = location.state?.token || "";

  const [isList,setIsList]=useState(false)

  return (
    <div className="MainScreen">
      <Sidemenu isList={isList}/>
      <ChatList setIsList={setIsList}/>     
    </div>
  );
}

export default ChatScreen;
