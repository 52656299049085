import React, { useState, useEffect } from "react";
import { requestNotificationPermission } from "./firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toast from "react-bootstrap/Toast";
import { setHistoryNotification, setNewMsgNotification, setNewMsgNotificationCount, setUpdateChatCount } from "../Redux/Slices/userSlices";
import { removeExtraPlusSigns } from "../Common/commonFunction/CommonFunction";

const FireBaseNotification = () => {
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const RdContactNo = useSelector((state) => state?.user?.contactNo);
  const RdCountryCode = useSelector((state) => state?.user?.countryCode);

  const [notification, setNotification] = useState({
    title: "",
    body: "",
    contactNum: "",
    countryCode: "",
  });

  const [showA, setShowA] = useState(false);

  const toggleShowA = () => {
    setShowA(true);
    setTimeout(() => setShowA(false), 6000);
  };


  const handleNotificationClick = () => {
    if (notification.contactNum) {
    navigate("/", {
      state: {
        contactNo: notification?.contactNum,
      countryCode: notification?.countryCode,  
      },
    })};
    setShowA(false);
  };

  function ToastDisplay() {
    return (
      <div className="FirbaseToaster">
        <Toast show={showA} onClick={handleNotificationClick} onClose={() => setShowA(false)}>
          <Toast.Header>
            <strong className="me-auto">{notification?.title}</strong>
          </Toast.Header>
          <Toast.Body>
            {notification?.body?.split(" ").slice(0, 16).join(" ")}...
          </Toast.Body>
        </Toast>
      </div>
    );
  }

  useEffect(() => {
    requestNotificationPermission();

    const handleServiceWorkerMessage = (event) => {
      const { data } = event;
      const newContactNo = data?.data?.contact_no;
      const newCountryCode = data?.data?.country_code;

      setNotification({
        title: data?.notification?.title,
        body: data?.notification?.body,
        contactNum: newContactNo,
        countryCode: newCountryCode,
      });

    
      // const notificationPayload = JSON?.parse(data?.data)
      const notificationPayload = data?.data;

      dispatch(setUpdateChatCount(notificationPayload.badge));


      let currentpath = location.pathname;

      const newMsgHistory =
      {
        "senderName": notificationPayload?.senderName,
        "message": notificationPayload?.message,
        "title": notificationPayload.title,
        "crd": notificationPayload?.crd,
        "crdOrg": notificationPayload?.crdOrg,
        "senderType": notificationPayload?.senderType,
        "msg_to": notificationPayload?.msg_to,
        "msg_from": notificationPayload?.msg_from,
        "status": notificationPayload?.status,
        "messagingServiceSid": null,
        "isMedia": notificationPayload?.isMedia,
        "imgUrl": notificationPayload?.imgUrl,
        "updatedDate": notificationPayload?.crdOrg,
        "unreadCount": notificationPayload?.unreadCount,
        "contactNo": notificationPayload?.contact_no,
        "countryCode": removeExtraPlusSigns(notificationPayload?.country_code),
        "lastMsg": notificationPayload?.message,
        "inboxId": notificationPayload?.inboxId,
        "unreadInboxCount": notificationPayload?.unreadInboxCount,
        "inboxName": notificationPayload?.inboxName,
        "badge": notificationPayload?.badge,
      }
      dispatch(setHistoryNotification(newMsgHistory));

      const newMsg =
      {
        "senderName": notificationPayload?.senderName,
        "message": notificationPayload?.message,
        "crd": notificationPayload?.crd,
        "crdOrg": notificationPayload?.crdOrg,
        "senderType": notificationPayload?.senderType,
        "msg_to": notificationPayload?.msg_to,
        "msg_from": notificationPayload?.msg_from,
        "status": notificationPayload?.status,
        "messagingServiceSid": null,
        "isMedia": notificationPayload?.isMedia,
        "imgUrl": notificationPayload?.imgUrl,
        "contactNo": notificationPayload?.contact_no,
        "countryCode": removeExtraPlusSigns(notificationPayload?.country_code),
        "lastMsg": notificationPayload?.message,
        "inboxId": notificationPayload?.inboxId,
        "unreadInboxCount": notificationPayload?.unreadInboxCount,
        "inboxName": notificationPayload?.inboxName,
      }

      dispatch(setNewMsgNotification(newMsg));

      const newMsgCount =
      {
        "inboxId": notificationPayload?.inboxId,
        "unreadInboxCount": notificationPayload?.unreadInboxCount,
        "inboxName": notificationPayload?.inboxName,
      }
      dispatch(setNewMsgNotificationCount(newMsgCount));

      // Show toast only if contactNo and countryCode are different from the current ones
      if (newContactNo !== RdContactNo || removeExtraPlusSigns(newCountryCode) !== removeExtraPlusSigns(RdCountryCode) || currentpath != "/") {
        const dispatcerMsg = notificationPayload?.sound;
        if (dispatcerMsg !== "silent") {
          toggleShowA();
        }
      } else {
        if (currentpath != "/") {
          const dispatcerMsg = notificationPayload?.sound;
          if (dispatcerMsg !== "silent") {
            toggleShowA();
          }
        }
      }
    };

    navigator.serviceWorker?.addEventListener("message", handleServiceWorkerMessage);

    return () => {
      navigator.serviceWorker.removeEventListener("message", handleServiceWorkerMessage);
    };
  }, [RdContactNo, RdCountryCode]);

  return (
    <>
      {ToastDisplay()}
    </>
  );
};

export default FireBaseNotification;
