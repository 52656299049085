import React, { useCallback, useEffect, useState } from "react";
import Sidemenu from "../SideMenubar/Sidemenu";
import style from "./Template.module.css";
import AddTemplate from "./AddTemplate";
import ReservisionApi from "../../helper/Api";
import Toster from "../../Common/Toster/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlices";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditTemplate from "./EditTemplate";
import { Dropdown, DropdownButton } from "react-bootstrap";
import moment from "moment";
import { debounce } from "lodash";
import Loader from "../../Common/loader/Loader";
import DeleteTemplate from "./DeleteTemplate";
import TopHeading from "../TopHeading /TopHeading";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { formatDateTime } from "../../Common/DateFormate";

function Templates() {
  const { t } = useTranslation();
  const currentTimeZoneState = useSelector(
    (state) => state.user.currentTimeZoneState
  );

  const authToken = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [isDropdown, setIsDropdown] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [templateList, setTemplateList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [updatedata, setUpdatedata] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    setShowDelete(false);
  };

  // manage the dropdown according to template id
  const handleDropdown = (index) => {
    setIsDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  // Template List api
  const handleGetTemplate = (isLoadMore = false) => {
    setIsLoading(true);
    if (!isLoadMore) {
      setOffset(0);
    }

    const newOffset = isLoadMore ? offset + limit : 0; // Reset offset to 0 if not loading more
    const res = ReservisionApi.getTemplateList(authToken, newOffset, searchKey);
    res.then((data) => {
      setIsLoading(false);
      if (data?.data?.status === "success" || data?.status === "success") {
        const newTemplateList = isLoadMore
          ? [...templateList, ...data.templateList]
          : data.templateList;
        setTemplateList(newTemplateList);
        setTotalCount(data.totalCount);
        setOffset(newOffset);
      } else if (
        data?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        data?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
      } else {
        // dispatch(userLogoutClear());
        Toster(data?.message, "error");
      }
    });
  };

  // Manage search template
  const handleChange = useCallback(
    debounce((event) => {
      setSearchKey(event.target.value);
      setOffset(0);
    }, 500),
    []
  );

  useEffect(() => {
    handleGetTemplate(false);
  }, [searchKey]);

  const handleSuccess = () => {
    setOffset(0);
    handleGetTemplate(false);
  };

  // DELETE QUESTION API
  const handleDelete = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setUpdatedata(item);
  };

  // onscroll function for manage pagination of template list
  const handleScroll = useCallback(
    debounce((e) => {
      const element = e.target;
      if (
        element.scrollHeight - element.scrollTop - element.clientHeight <= 1 &&
        templateList.length < totalCount &&
        !isLoading
      ) {
        handleGetTemplate(true);
      }
    }, 200),
    [templateList, totalCount, isLoading]
  );

  return (
    <div className="TemplatePage">
      <Sidemenu />
      <div className={style.TemplateContent}>
        <TopHeading
          heading={t("TEMPLATES_HEADING")}
          handleChange={handleChange}
          handleShow={handleShow}
          btnName={t("ADD_TEMP")}
        />

        {/*  Template list */}
        {isLoading && offset === 0 ? (
          <Loader />
        ) : templateList?.length === 0 ? (
          <p className="NoDataFound">{t("NO_DATA_FOUND")}</p>
        ) : (
          <div className="grid-container" onScroll={handleScroll}>
            {templateList.map((item, index) => (
              <div key={index} className="card">
                <div className="dropdown">
                  <h2>{item.title}</h2>
                  <DropdownButton
                    title="&#8230;"
                    id={`dropdown-button-drop`}
                    onClick={() => handleDropdown(index)}
                  >
                    <Dropdown.Item onClick={() => handleEdit(item)}>
                      {t("EDIT")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDelete(item.id)}>
                      {t("DELETE")}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <span>
                  {t("LAST_UPDATE")}{" "}
                  {formatDateTime(item.crd, currentTimeZoneState?.timeZoneTxt)}
                </span>
                <p style={{ whiteSpace: 'pre-wrap' }}>{item.text}</p>
              </div>
            ))}
            {isLoading && offset > 0 && <Loader />}
          </div>
        )}
      </div>
      <AddTemplate
        onSuccess={handleSuccess}
        show={show}
        handleClose={handleClose}
      />
      <EditTemplate
        isEdit={isEdit}
        handleClose={handleClose}
        updatedata={updatedata}
        onSuccess={handleSuccess}
      />
      <DeleteTemplate
        showDelete={showDelete}
        handleClose={handleClose}
        deleteId={deleteId}
        onSuccess={handleSuccess}
      />
    </div>
  );
}

export default Templates;
