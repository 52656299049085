import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import styles from "./Template.module.css";
import { useTranslation } from "react-i18next";

function TokenListModel({
  showTokenList,
  handleTokenClose,
  tokenList,
  setSelectedTokenData,
}) {
  const { t } = useTranslation();

  // manage for selcted token
  const handleTokenClick = (token, description) => {
    setSelectedTokenData({ token, description });
    handleTokenClose();
  };

  return (
    <Modal show={showTokenList} onHide={handleTokenClose} >
      <Modal.Header>
        <Modal.Title className={styles.ModalHeading}>{t("TOKENS")}</Modal.Title>
      </Modal.Header>
      <Form className="TokenModal">
        <Modal.Body>
          {tokenList.map((token) => (
            <div
              key={token.tokenId}
              className={styles.TokenData}
              onClick={() =>
                handleTokenClick(token.token, token.token_description)
              }
            >
              <p>{token.token}</p>
              <p>{token.token_description}</p>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button
            className={styles.CancelBtn}
            type="button"
            onClick={handleTokenClose}
          >
            {t("CANCEL")}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default TokenListModel;
