import React, { useCallback, useEffect, useRef, useState } from "react";
import Sidemenu from "../SideMenubar/Sidemenu";
import style from "./Timezone.module.css";

import ReservisionApi from "../../helper/Api";
import Toster from "../../Common/Toster/Toster";
import {
  setCurrentTimeZone,
  userLogoutClear,
} from "../../Redux/Slices/userSlices";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, ListGroup } from "react-bootstrap";

import { debounce } from "lodash";
import Loader from "../../Common/loader/Loader";

import { STATUS_CODES } from "../../Utils/StatusCode";

import { Form } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { withoutPTag } from "../../Common/ReplacePtag";
function Timezone() {
  const { t } = useTranslation();
  const authToken = useSelector((state) => state.user.userToken);
  const currentTimeZoneState = useSelector(
    (state) => state.user.currentTimeZoneState
  );
  const dispatch = useDispatch();

  const [searchKey, setSearchKey] = useState("");
  const [timezoneList, setTimezoneList] = useState([]);
  const [totalCount, setTotalCount] = useState(0); // Initialize totalCount with 0
  const [isLoading, setIsLoading] = useState(true);

  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const searchInputRef = useRef(null);

  // Function to handle selecting an item
  const handleSelect = (item) => {
    setSelectedItem(item);
  };

  // Function to fetch timezone list
  const handleGetTimezone = (isLoadMore = false) => {
    setIsLoading(true);
    if (!isLoadMore) {
      setOffset(0);
    }

    const newOffset = isLoadMore ? offset + limit : 0; // Reset offset to 0 if not loading more
    const res = ReservisionApi.GetTimezoneList(
      authToken,
      limit,
      newOffset,
      searchKey
    );
    res.then((responseData) => {
      setIsLoading(false);

      if (responseData?.status === "success") {
        const newTimezoneList = isLoadMore
          ? [...timezoneList, ...responseData?.data]
          : responseData?.data;

        setTimezoneList(newTimezoneList);
        setTotalCount(responseData.count);
        setOffset(newOffset);
      } else if (
        responseData?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        responseData?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
      } else {
        // dispatch(userLogoutClear());
        Toster(responseData?.message, "error");
      }
    });
  };

  // Effect to fetch timezone list based on searchKey
  useEffect(() => {
    if (searchKey) {
      setSelectedItem(null); // Clear selectedItem when searchKey changes
    }
    handleGetTimezone(false);
  }, [searchKey]);

    // Function to handle debounced search input change
    const handleChange = useCallback(
      debounce((event) => {
        setSearchKey(event.target.value);
        setOffset(0);
      }, 500),
      []
    );
  

  // Function to handle form submission
  const handleSubmit = () => {
    if (selectedItem) {
      let postData = new FormData();
      postData.append("timezoneId", selectedItem.id);

      let res = ReservisionApi.updateTimezone(authToken, postData);
      res.then((responseData) => {
        if (responseData.status === "success") {
          Toster(responseData?.message, "success");
          dispatch(setCurrentTimeZone(selectedItem));
          setTimezoneList([]);
          setSearchKey("");
          if (searchInputRef.current) {
            searchInputRef.current.value = ""; // Clear the search input field
          }
        } else if (responseData?.responseCode === STATUS_CODES.INVALID_TOKEN) {
          window.location.href = process.env.REACT_APP_REDIRECT_URL;
        } else {
          // dispatch(userLogoutClear());
          Toster(withoutPTag(responseData?.message), "error");
        }
      });
    }
  };

  // Function to handle scrolling and fetch more timezone items
  const handleScroll = useCallback(
    debounce((e) => {
      const element = e.target;
      if (
        element.scrollHeight - element.scrollTop - element.clientHeight <= 1 &&
        timezoneList.length < totalCount &&
        !isLoading
      ) {
        handleGetTimezone(true);
      }
    }, 200),
    []
  );

  return (
    <div className="TemplatePage">
      <Sidemenu />
      <div className={style.timezoneContent}>
        <div className="HeadingName">
          <h1>Time Zone Setting</h1>
        </div>

        <div className="grid-container" onScroll={handleScroll}>
          <div className={style.mainDiv}>
            <Form.Group className="SearchTemplate">
              <CiSearch size={25} style={{ margin: "5px" }} />
              <Form.Control
                type="text"
                placeholder={t("SEARCH")}
                onChange={(e) => handleChange(e)}
                ref={searchInputRef} 
              />
            </Form.Group>

            {searchKey && (
              <div>
                <ListGroup className={style.listGroup} onScroll={handleScroll}>
                  {timezoneList.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      onClick={() => handleSelect(item)}
                    >
                      <Form.Check
                        type="radio"
                        name="searchRadio"
                        value={item.timeZone}
                        checked={selectedItem?.id === item.id}
                        onChange={() => handleSelect(item)}
                        label={item.timeZoneTxt}
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <div className={style.centerDiv}>
                  <Button
                    onClick={handleSubmit}
                    as="input"
                    type="button"
                    value="Done"
                  />
                </div>
              </div>
            )}

            <div style={{ padding: "10px" }}>
              <strong>
                Current Time Zone : {currentTimeZoneState?.timeZoneTxt}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Timezone;
