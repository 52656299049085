import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PropTypes from "prop-types";
import { Form } from 'react-bootstrap';
import styles from "../../appComponent/Contact/Contact.module.css"

const CustomPhoneInput = ({
  value,
  onChange,
  placeholder,
  errors,
  inputProps,
}) => {
  return (
    <Form.Group className={styles.PhoneInput}>
      <PhoneInput
        placeholder={placeholder}
        country={"us"}
        // disableDropdown={true}
        // countryCodeEditable={false}
        // onlyCountries={["in"]}
        inputProps={inputProps}
        value={value}
        onChange={onChange}
        inputClass={styles.PhoneInput}
      />
      {errors && errors.phoneNumber && (
        <p className="error">{errors.phoneNumber.message}</p>
      )}
    </Form.Group>
  );
};

CustomPhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  inputProps: PropTypes.object,
};

CustomPhoneInput.defaultProps = {
  placeholder: "Enter number",
  errors: null,
  inputProps: { maxLength: 17 },
};

export default CustomPhoneInput;
