import i18n from "./i18n/i18n";
import "./App.css";
import Router from "./Routes/Router";
import { I18nextProvider } from "react-i18next";
import { ToastContainer,Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
      <ToastContainer transition={Slide} />
    </div>
  );
}

export default App;
