import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const CommonTextarea = ({
  placeholder,
  name,
  register,
  errors,
  validation,
  validationSchema,
  t,
  ...rest
}) => {
  return (
    <Form.Group className="">
      <Form.Control
        as="textarea"
        rows={5}
        placeholder={placeholder}
        name={name}
        {...register(name, {
          ...validationSchema,
          validate: (value) => value?.trim() !== "" || "Message should not be less than 25 character",
          ...validation,
        })}
        {...rest}
      />
      {errors[name] && (
        <p className="error">
          {t ? t(errors[name].message) : errors[name].message}
        </p>
      )}
    </Form.Group>
  );
};

CommonTextarea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  t: PropTypes.func,
};

export default CommonTextarea;
