// dateFormatter.js
import moment from "moment-timezone";

export const formateDate = (date,timezone=moment.tz.guess()) => {
  var localDateTime = moment.utc(date).tz(timezone).format('MM/DD/YYYY');
  return localDateTime;
  // return moment(date, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY");
};

export const formatUpdatedDate = (date, timezone = moment.tz.guess()) => {
  const localDateTime = moment.utc(date).tz(timezone);
  const now = moment.tz(timezone);
  
  if (localDateTime.isSame(now, 'day')) {
    return localDateTime.format('hh:mm A'); // Format as time if today
  } else if (localDateTime.isSame(now.clone().subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  } else if (localDateTime.isSame(now, 'week')) {
    return localDateTime.format('dddd'); // Format as weekday name if within the same week
  } else {
    return localDateTime.format('MM/DD/YYYY'); // Format as full date if older than a week
  }
};

export const formatDateTime = (utcTimestamp,timezone=moment.tz.guess()) => {
  // Convert to local time zone
  var localDateTime = moment.utc(utcTimestamp).tz(timezone).format('MM/DD/YYYY, h:mm a');
  return localDateTime;
};

