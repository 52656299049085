import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import styles from "./Setting.module.css";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CommonInput } from "../../Common/commonInput/CommonInput";
import ReservisionApi from "../../helper/Api";
import Toster from "../../Common/Toster/Toster";
import { useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { ValidationSchema } from "../../Common/ValidationSchema";
import { formatPhoneNumberWithMask } from "../../Common/commonFunction/CommonFunction";

function EditIndex({ isEdit, handleClose, selectedInbox, onSuccess }) {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();

  const authToken = useSelector((state) => state.user.userToken);

  const [activeNumber, setActiveNumber] = useState([]);

  const handleNumberLists = () => {
    ReservisionApi.getActiveNumList(authToken)
      .then((res) => {
        if (res?.data?.status === "success" || res?.status === "success") {
          Toster(res?.message, "success");
          setActiveNumber(res.data.activeNumberList);
        } else {
          Toster(res?.message, "error");
        }
      })
      .catch((error) => {
        Toster("Error fetching inbox list", "error");
      });
  };

  useEffect(() => {
    if (isEdit && !selectedInbox) {
      handleNumberLists();
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && selectedInbox) {
      reset({
        index: selectedInbox.name,
        phone_number: selectedInbox.dialCode + selectedInbox.number,
      });
    } else {
      reset({
        index: "",
        phone_number: "",
      });
    }
  }, [isEdit, selectedInbox, reset]);

  const onSubmit = (formData) => {
    let Postdata = new FormData();
    Postdata.append("name", formData.index);

    if (!selectedInbox) {
      if (formData.phone_number) {
        Postdata.append("contact", formData.phone_number); // Add contact if available
      }

      ReservisionApi.addInbox(authToken, Postdata).then((response) => {
        if (
          response?.data?.status === "success" ||
          response?.status === "success"
        ) {
          Toster(response?.message, "success");
          handleClose();
          onSuccess();
        } else if (
          response?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
          response?.responseCode === STATUS_CODES.INVALID_TOKEN
        ) {
          window.location.href = process.env.REACT_APP_REDIRECT_URL;
        } else {
          Toster(response?.message, "error");
        }
      });
    } else {
      // "Edit Inbox" case
      Postdata.append("inboxId", selectedInbox.id);
      Postdata.append("phone_number", formData.phone_number);
      ReservisionApi.updateIndex(authToken, Postdata).then((response) => {
        if (
          response?.data?.status === "success" ||
          response?.status === "success"
        ) {
          Toster(response?.message, "success");
          handleClose();
          onSuccess();
        } else if (
          response?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
          response?.responseCode === STATUS_CODES.INVALID_TOKEN
        ) {
          window.location.href = process.env.REACT_APP_REDIRECT_URL;
        } else {
          Toster(response?.message, "error");
        }
      });
    }
  };

  return (
    <div className="IndexModal">
      <Modal
        show={isEdit}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="IndexHeader">
          <Modal.Title>
            {selectedInbox ? t("Edit Inbox") : t("Add Inbox")}
          </Modal.Title>
        </Modal.Header>
        <Form className="indexModal" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <CommonInput
              type="text"
              placeholder={t("Inbox")}
              name="index"
              register={register}
              errors={errors}
              validationSchema={ValidationSchema.Index}
              getValues={getValues}
              t={t}
            />
            {!selectedInbox && (
              <Form.Group>
                <Form.Control as="select" {...register("phone_number")}>
                  <option value="">{t("Select a number")}</option>
                  {activeNumber.map((num) => (
                    <option
                      key={num.phone_number}
                      value={num.phone_number}
                      disabled={num.is_already_added}
                    >
                      {num.phone_number
                        ? formatPhoneNumberWithMask(
                            num.dial_code,
                            num.national_number
                          )
                        : ""}
                      {num.is_already_added}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {selectedInbox && (
              <div className={styles.contactInfo}>
                <p>{t("Linked Phone Number")}:</p>
                <span>
                  {" "}
                  {selectedInbox.contact
                    ? formatPhoneNumberWithMask(
                        selectedInbox.dialCode,
                        selectedInbox.number
                      )
                    : ""}
                </span>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className={styles.CancelBtn} type="submit">
              {t("SAVE")}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default EditIndex;
