import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import styles from "./Contact.module.css";
import { useForm } from "react-hook-form";
import ReservisionApi from "../../helper/Api";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FiUser } from "react-icons/fi";
import { userLogoutClear } from "../../Redux/Slices/userSlices";
import Toster from "../../Common/Toster/Toster";
import { removeExtraPlusSigns } from "../../Common/commonFunction/CommonFunction";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { ValidationSchema } from "../../Common/ValidationSchema";
import {
  CommonInput,
  FirstInput,
  LastNameInput,
} from "../../Common/commonInput/CommonInput";
import CustomPhoneInput from "../../Common/customPhoneInput/CustomPhoneInput";
import { withoutPTag } from "../../Common/ReplacePtag";

function CreateContact({ show, handleClose, onSuccess }) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilen, setSelectedFilen] = useState(null);

  const [imgError, setImgError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [loading, setLoading] = useState(false);

  // Phone number
  const handelPhoneChange = (value, data) => {
    const actualNumber = value.slice(data.dialCode.length);
    setPhoneNumber(actualNumber);
    setDialCode(data.dialCode);
    setCountryCode(data.countryCode);
    setValue("phoneNumber", actualNumber);
  };

  const handleImageChange = () => {
    // Click the file input programmatically to open the file selection dialog
    const fileInput = document.getElementById("file-input");
    fileInput.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFilen(file);
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result);
      };
      reader.readAsDataURL(file);
      setImgError("");
    } else {
      setImgError(t("FILE_NOT_ALLOW"));
    }
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    let Postdata = new FormData();
    Postdata.append("firstName", formData.firstName);
    Postdata.append("lastName", formData.lastName);
    Postdata.append("email", formData.Email);
    Postdata.append("countryIsoCode", countryCode);
    Postdata.append(
      "countryCode",
      dialCode ? removeExtraPlusSigns(dialCode) : ""
    );
    Postdata.append("contactNo", phoneNumber);
    Postdata.append("profileImage", selectedFilen);

    try {
      const data = await ReservisionApi.addContactDetail(authToken, Postdata);
      if (data?.data?.status === "success" || data?.status === "success") {
        Toster(data?.message, "success");
        handleClose();
        onSuccess();
        reset();
        setSelectedFile(null);
        setSelectedFilen(null);
        setPhoneNumber("");
        setDialCode("");
      } else if (
        data?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        data?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        // window.location.href = process.env.REACT_APP_REDIRECT_URL;
      } else {
        // dispatch(userLogoutClear());
        Toster(withoutPTag(data?.message), "error");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className={styles.ModalHeading}>
          {t("NEW_CONTACT")}
        </Modal.Title>
      </Modal.Header>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="ContactModal"
      >
        <Modal.Body>
          <div className={styles.FileUpload}>
            {selectedFile ? (
              <div className={styles.imgUpload}>
                <img
                  className={styles.afterUploadImg}
                  src={selectedFile}
                  alt="Uploaded"
                />
                {selectedFile !== null && selectedFilen !== null && (
                  <h3 className="NotifiyMsg" onClick={handleImageChange}>
                    {t("CHANGE_IMG")}
                  </h3>
                )}
              </div>
            ) : (
              <div className={styles.uploadIcon}>
                <label htmlFor="file-input" className={styles.uploadLabel}>
                  <FiUser className="upload-icon" />
                </label>
              </div>
            )}

            <input
              type="file"
              id="file-input"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <div className="errorMsg">
              {imgError && <p className="error">{imgError}</p>}
            </div>
            {selectedFile === null && selectedFilen === null && (
              <h3 className="NotifiyMsg" onClick={handleImageChange}>
                {t("ADD_IMG")}
              </h3>
            )}
          </div>
          <FirstInput
            type="text"
            placeholder={t("FIRST_NAME")}
            name="firstName"
            register={register}
            errors={errors}
            validationSchema={ValidationSchema.firstName}
            t={t}
          />

          <LastNameInput
            type="text"
            placeholder={t("LAST_NAME")}
            name="lastName"
            register={register}
            errors={errors}
            validationSchema={ValidationSchema.lastName}
            t={t}
          />

          <CustomPhoneInput
            value={`${removeExtraPlusSigns(
              dialCode
            )}${countryCode}${phoneNumber}`}
            onChange={(value, data) => handelPhoneChange(value, data)}
            placeholder={t("ENTER_NUNBER")}
            errors={errors}
            inputClass={styles.PhoneInput}
            getValues={getValues}
            name="phone"
            t={t}
          />

          <CommonInput
            type="Email"
            placeholder={t("EMAIL_ADDRESS")}
            name="Email"
            register={register}
            errors={errors}
            validationSchema={ValidationSchema.Email}
            getValues={getValues}
            t={t}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className={styles.CancelBtn}
            type="button"
            onClick={() => {
              handleClose();
              reset();
              setPhoneNumber("");
              setDialCode("");
              setSelectedFile(null);
              setSelectedFilen(null);
            }}
          >
            {t("CANCEL")}
          </button>
          <button
            className={`${styles.CancelBtn} ${
              isValid && !loading ? "" : styles.DisabledBtn
            }`}
            type="submit"
            disabled={!isValid || loading}
          >
            {loading ? t("SAVE") : t("SAVE")}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateContact;
