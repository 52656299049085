// TopHeading.js
import React from "react";
import { Form } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { useTranslation } from "react-i18next";

const TopHeading = ({ heading, handleChange, handleShow, btnName }) => {
  const { t } = useTranslation();

  return (
    <div className="HeadingName">
      <h1>{heading}</h1>
      <div className="TopHeading">
        <Form.Group className="SearchTemplate">
          <CiSearch size={25} style={{ margin: "5px" }} />
          <Form.Control
            type="text"
            placeholder={t("SEARCH")}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <button onClick={() => handleShow()}>{btnName}</button>
      </div>
    </div>
  );
};

export default TopHeading;
